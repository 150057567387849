import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class DataService {

    constructor(private http: HttpClient) {
    }

    public get<T>(url: string, options?: { context?: HttpContext }): Observable<T> {
        if (options && options.context) {
            return this.http.get<T>(url, { context: options.context });
        } else {
            return this.http.get<T>(url);
        }
    }

    public getWithParams<T>(url: string, params: HttpParams): Observable<T> {
        return this.http.get<T>(url, { params });
    }

    public create<T>(url: string, body: any, fileType: string): Observable<T> {

        const reqHeader: Object = { responseType: 'json' };
        return this.http.post<T>(url, body, reqHeader);
    }

    public postWithParam<T>(url: string, param: any, body: any, fileType: string): Observable<T> {

        const reqHeader: Object = {
            responseType: 'json',
            params: param
        };
        return this.http.post<T>(url, body, reqHeader);
    }

    public update<T>(url: string, body: any): Observable<T> {
        return this.http.put<T>(url, body);
    }

    public delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(url);
    }
}