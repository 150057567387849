import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthAccountService } from 'src/app/core/_services/auth.account.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'partner-mobile-navigation',
  templateUrl: './partner-mobile-navigation.component.html',
  styleUrls: ['./partner-mobile-navigation.component.scss']
})
export class PartnerMobileNavigationComponent {
  menuExpanded: string;
  @Input() fullName: string = '';
  @Input() address: string = '';
  @Output() linkClicked = new EventEmitter<void>();

  constructor(
    private changeRef: ChangeDetectorRef,
    private toastr: ToastrService,
    private authAccountService: AuthAccountService,
  ) {}

  ngOnInit(): void {
    this.menuExpanded = undefined;
  }

  onNavLinkClick() {
    this.linkClicked.emit();
    this.menuExpanded = undefined;
  }

  activeMenuEvent(event) {
    const parent = (event.target as HTMLElement).parentElement;

    // Check if parent exists
    if (!parent) {
        return;
    }

    // Remove class from siblings
    const siblings = parent.parentElement?.querySelectorAll('.dashboard_sidebar-item.active');
    siblings?.forEach(sibling => sibling.classList.remove('active'));

    // Add class to parent element
    parent.classList.add('active');
  }

  comingSoon() {
    this.toastr.info('This feature is coming soon...');
  }
  expandMenu(id: string) {
    this.menuExpanded = id;
    this.changeRef.detectChanges();
  }
  setMenuClass(id: string) {
    return this.menuExpanded == id ? 'd-block' : 'd-none';
  }

  logout() {
    Swal.fire({
      title: 'Logout as Seller?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#ff6d41',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        this.authAccountService.clearUserSession();
        window.location.href = '/';
      }
    });
  }
}
