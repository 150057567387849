<div class="header-searchWrap ml-2" data-toggle="modal" data-target="#searchModal">
      <input
        class="form-control"
        type="text"
        placeholder="Search for your favourite business, deals & coupons..."
        aria-label="Search"
        [(ngModel)]="searchText"
      />

      <button 
        class="btn custom-btn-orange light"
        type="submit"
        title="Search"
      >
        <span>Search</span>
        <i class="fa fa-search"></i>
      </button>
    
    <!-- <input
      class="form-control"
      type="text"
      placeholder="Location, Zip Code"
      aria-label="Search Location, Zip Code"
      [(ngModel)]="searchLocation"
    /> -->
    <!-- <button 
      class="btn custom-btn-orange light"
      type="submit"
      (click)="doSearch()"
      title="Search"
    >
      <span>Search</span>
      <i class="fa fa-search"></i>
    </button> -->
  </div>

  



  <!-- <button type="button" class="btn btn-primary" >Large modal</button> -->


<!-- <div *ngIf="showSearchProgress" class="progress mt-1" style="height: 5px">
  <div
    class="progress-bar progress-bar-striped progress-bar-animated"
    role="progressbar"
    aria-label="Animated striped example"
    aria-valuenow="75"
    aria-valuemin="0"
    aria-valuemax="100"
    style="width: 75%"
  ></div>
</div> -->
