<section class="deals_oftheday-wrapper">
  <div class="container w-100">
    <div class="row">
      <div class="col-12">
        <h2 class="primary-heading text-center">
          Local Deals &<span class="orange-text"> Offers</span>
        </h2>

        <owl-carousel-o *ngIf="!isloading; else loadingTemplate" [options]="dealCaraouselOptions">
          <ng-container *ngFor="let deal of dealsOfTheDay">
            <ng-template carouselSlide>
              <div class="deals_oftheday-cardWrapper">
                <div class="deals_oftheday-cardWrapper-inner">
                  <div class="deals_oftheday-card-header">
                    <img [src]="deal.photos[0].photopath" alt="" loading="lazy" />
                    <span class="deals_oftheday-discount"
                      >{{ calcDiscount(deal) }}% OFF</span
                    >
                  </div>

                  <div class="deals_oftheday-card-body">
                    
                    <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      gap: 7px;
                      margin-bottom: 5px;
                    "
                  >
                    <div style="font-size: 13px; color: #a1a1a1;">{{deal.averageRating.toFixed(1)}}</div>

                    <div>
                      <star-rating [rating]="deal.averageRating.toFixed(1)" [size]="'sm'"></star-rating>
                    </div>

                    <div style="font-size: 13px; color: #a1a1a1;">{{deal.totalRating}} ratings</div>
                  </div>

                  <div style="font-size: 14px; color: #a1a1a1;margin-bottom: 5px; min-height: 25px; max-height: 40px;overflow: hidden;
                  white-space: nowrap;">{{deal.registerUser.businessName}}</div>
                  <h6
                    class="deal-subtitle"
                    style="
                      min-height: 36px;
                      max-height: 36px;
                      overflow: hidden;
                      color: #000000;
                    "
                  >
                    {{ deal.products[0].productName }}
                  </h6>

                    <!-- <h3 class="deal-title" [innerHTML]="deal.description"></h3> -->

                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                        height: 19px;
                      "
                    >
                      <h2 class="deal-price" style="font-size: 16px; font-weight: 800;">
                        Price:
                        <span style="color: #00d02e;"
                          >   ${{ deal.products[0].dealPrice }}</span
                        >
                      </h2>

                      <p
                        style="
                          color: #fe6a3e;
                          font-weight: bold;
                          font-size: 18px;
                        "
                        onmouseover="this.style.cursor='pointer'"
                        (click)="getThisDeal(deal.dealId)"
                      >
                        Buy Now
                      </p>
                    </div>

                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-direction: row;
                      "
                    >
                      <h2
                        class="deal-normalprice"
                        style="
                          color: #a1a1a1;
                          font-size: 16px;
                          margin-bottom: 2rem;
                          font-family: Arial, sans-serif;
                        "
                      >
                        ${{ deal.products[0].normalPrice }}
                      </h2>

                      <h2
                        style="
                          margin-bottom: 2rem;
                          color: #a1a1a1;
                          font-size: 14px;
                          font-weight: 500;
                        "
                      >
                        Expires On {{formatDate(deal.expiryDate)}}
                      </h2>
                    </div>
                  </div>
                  <!-- <div class="deals_oftheday-card-footer">
                    <button type="button" class="custom-btn" title="Get This Deal" (click)="getThisDeal(deal.dealId)">
                      Get This Deal
                    </button>
                  </div> -->
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>

        <div class="custom-btn-wrapper-lrg">
          <button *ngIf="!isloading" class="btn custom-btn-large-orange" routerLink="/customer/product-search">
            <span>View More Deals</span>
            <div class="icon-wrapper">
              <i class="fa fa-angle-right" aria-hidden="true"></i>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #loadingTemplate>
  <owl-carousel-o [options]="dealCaraouselOptions">
    <ng-container *ngFor="let group of [].constructor(4);">
      <ng-template carouselSlide>
        <div class="deals_oftheday-cardWrapper">
          <div class="deals_oftheday-cardWrapper-inner is-loading">
            <div class="deals_oftheday-card-header">
              <img alt="" />
            </div>
  
            <div class="deals_oftheday-card-body">
              
              <div
              style="
                display: flex;
                justify-content: flex-end;
                margin-bottom: 5px;
              "
              class="rating-span"
            >
              <div style="font-size: 13px;border-radius: 0;">3</div>

              <div style="border-radius: 0;">
                rating stars
              </div>

              <div style="font-size: 13px; border-radius: 0;">450 ratings</div>
            </div>

            <span style="font-size: 14px; margin-bottom: 5px; min-height: 25px; max-height: 40px;overflow: hidden;
            white-space: nowrap;">business name</span>
            <h6
              class="deal-subtitle"
              style="
                min-height: 36px;
                max-height: 36px;
                overflow: hidden;
              "
            >
              product name
            </h6>

              <!-- <h3 class="deal-title" [innerHTML]="deal.description"></h3> -->

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-direction: row;
                  height: 19px;
                "
              >
                <h2 class="deal-price" style="font-size: 16px; font-weight: 800;">
                  Price:
                  <span style="color: #00d02e;"
                    >   $100</span
                  >
                </h2>

                <h5
                  style="
                    color: #fe6a3e;
                    font-weight: bold;
                    font-size: 18px;
                  "
                >
                  Buy Now
              </h5>
              </div>

              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  flex-direction: row;
                "
              >
                <h2
                  class="deal-normalprice"
                  style="
                    color: #a1a1a1;
                    font-size: 16px;
                    margin-bottom: 2rem;
                    font-family: Arial, sans-serif;
                  "
                >
                  
                </h2>

                <h2
                  style="
                    margin-bottom: 2rem;
                    color: #a1a1a1;
                    font-size: 14px;
                    font-weight: 100;
                  "
                >
                  Expires On sample date
                </h2>
              </div>
            </div>
            <!-- <div class="deals_oftheday-card-footer">
              <button type="button" class="custom-btn" title="Get This Deal" (click)="getThisDeal(deal.dealId)">
                Get This Deal
              </button>
            </div> -->
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</ng-template>
