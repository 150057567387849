<div class="banner">
    <div class="content">
        <h2>Get the best deals! Download the app for instant access to coupons and offers,and stay updated instantly.
            Start saving today</h2>
        <img src="../../../../assets/images/mobileAppPromotionQR.png" alt="QR Code" class="qr-code">
        <a href="https://play.google.com/store/apps/details?id=com.locoldeal&pcampaignid=web_share" target="_blank">
            <img src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Get it on Google Play" class="google-play">
        </a>
    </div>
    <div class="image-container">
        <img src="../../../../assets/images/mobileImage.webp" alt="Mobile App Preview" class="mobile-image">
    </div>
</div>