import { NgModule } from '@angular/core';
import { BaseComponent } from './base/base.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModuleCustom } from '../common/common.module';
import { AuthCommonModule } from '../auth-common/auth-common.module';
import { PartnerHeaderComponent } from './partner-header/partner-header.component';
import { PartnerChatComponent } from './partner-chat/partner-chat.component';
import { HeaderSearchComponent } from './header-search/header-search.component';
import { HeaderCustomerProfileComponent } from './header-customer-profile/header-customer-profile.component';
import { CheckboxComponent } from './multi-select-checkbox/multi-select-checkbox';
import { CheckboxGroupComponent } from './multi-select-checkbox/checkbox-group';
import { GalleryComponent } from './gallery/gallery.component';
import { ViewerComponent } from './viewer/viewer.component';
import { ImageService } from 'src/app/services/image.service';
import { AppMobileNavigationComponent } from './app-mobile-navigation/app-mobile-navigation';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StarRating } from './bar-rating-stars/bar-rating-stars';
import { PartnerMobileNavigationComponent } from './partner-mobile-navigation/partner-mobile-navigation.component';

@NgModule({
    declarations: [BaseComponent, HeaderComponent, FooterComponent, PartnerHeaderComponent,  PartnerChatComponent, HeaderSearchComponent, HeaderCustomerProfileComponent, CheckboxGroupComponent, CheckboxComponent, AppMobileNavigationComponent, GalleryComponent, ViewerComponent, PartnerMobileNavigationComponent],
    exports: [
        BaseComponent, HeaderComponent, FooterComponent, PartnerHeaderComponent, CheckboxGroupComponent, CheckboxComponent, GalleryComponent, ViewerComponent, FontAwesomeModule, StarRating
    ],
    providers: [ImageService],
    imports: [       
        RouterModule,
        CommonModule,        
        FormsModule,        
        ReactiveFormsModule, 
        AuthCommonModule,
        CommonModuleCustom,
        FontAwesomeModule,
        StarRating
    ]
})
export class ThemeModule { }
