<div class="dashboard_sidebar-wrapper">
    <div class="dashboard_sidebar-logoWrap">
      <a routerLink="/partner">
        <div class="navbar-logo d-flex align-items-center justify-content-center">
            <img class="logo" src="assets/images/logos/locoldeal-logo.svg" alt="Logo" style="height: 25px;" />
        </div>
      </a>
    </div>
    <div class="dashboard_sidebar-user">
      <div class="d-flex flex-column">
        <h5>{{ fullName }}</h5>
        <span>{{ address }}</span>
      </div>
    </div>
    <ul class="dashboard_sidebar-list">
      <li class="dashboard_sidebar-item" id="analytics-dd"
      routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['profile']"
          (click)="onNavLinkClick()"
        >
          <span class="icon"><i class="fa fa-user" aria-hidden="true"></i></span>
          <span class="link">My Profile</span>
        </a>
      </li>
      <li class="dashboard_sidebar-item"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a
          class="dashboard_sidebar-link"
          routerLink="/partner"
          (click)="onNavLinkClick()"
          ><span class="icon"><i class="icofont-card"></i></span>
          <span class="link">Coupons</span>
        </a>
      </li>
      <li
        class="dashboard_sidebar-item dropdown"
        id="deals-dd"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        (click)="expandMenu('orders')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['deals', { status: 'draft' }]"
        >
          <span class="icon"><i class="icofont-ember"></i></span>
          <span class="link">Deals</span>
        </a>
        <ul
          class="dashboard_sidebar-dropdown-list"
          [class]="setMenuClass('orders')"
        >
          <li class="dashboard_sidebar-dropdown-item">
            <a
              class="dashboard_sidebar-dropdown-link"
              [routerLink]="['deals-active', { status: 'active' }]"
              (click)="onNavLinkClick()"
              ><span class="icon"
                ><i class="fa fa-check" aria-hidden="true"></i></span
              ><span class="link">Active</span></a
            >
          </li>
          <li class="dashboard_sidebar-dropdown-item">
            <a
              class="dashboard_sidebar-dropdown-link"
              [routerLink]="['deals', { status: 'draft' }]"
              (click)="onNavLinkClick()"
              ><span class="icon"
                ><i class="fa fa-times" aria-hidden="true"></i></span
              ><span class="link">Draft</span></a
            >
          </li>
          <li class="dashboard_sidebar-dropdown-item">
            <a
              class="dashboard_sidebar-dropdown-link"
              [routerLink]="['deals-inactive', { status: 'inactive' }]"
              (click)="onNavLinkClick()"
              ><span class="icon"
                ><i class="fa fa-file-text-o" aria-hidden="true"></i></span
              ><span class="link">Inactive</span></a
            >
          </li>
        </ul>
      </li>
      <li class="dashboard_sidebar-item" id="orders-dd" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['orders']"
          (click)="onNavLinkClick()"
        >
          <span class="icon"><i class="icofont-cart"></i> </span>
          <span class="link">Orders</span></a
        >
      </li>
      <!-- Hidden as of now -->
      <li
        class="dashboard_sidebar-item dropdown d-none"
        id="notis-dd"
        (click)="expandMenu('notifications')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['notifications']"
          (click)="activeMenuEvent($event)"
        >
          <span class="icon"><i class="icofont-bell-alt"></i></span>
          <span class="link">Notifications</span>
        </a>
        <ul [class]="setMenuClass('notifications')">
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Active</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Draft</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Inactive</a>
          </li>
        </ul>
      </li>
      <!-- Hidden as of now -->
      <li
        class="dashboard_sidebar-item dropdown d-none"
        id="customers-dd"
        (click)="expandMenu('customers')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['customers']"
          (click)="activeMenuEvent($event)"
        >
          <span class="icon"><i class="icofont-man-in-glasses"></i></span>
          <span class="link">Consumers</span>
        </a>
        <ul [class]="setMenuClass('customers')">
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Active</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Draft</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Inactive</a>
          </li>
        </ul>
      </li>
      <li
        class="dashboard_sidebar-item"
        id="analytics-dd"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
        (click)="expandMenu('analytics')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['analytics']"
          (click)="onNavLinkClick()"
          ><span class="icon"
            ><i class="fa fa-bar-chart" aria-hidden="true"></i
          ></span>
          <span class="link">Analytics</span>
        </a>
      </li>    
      <!-- Hidden as of now -->
      <li
        class="dashboard_sidebar-item dropdown d-none"
        id="marketing-dd"
        (click)="expandMenu('marketing')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['marketing']"
          (click)="activeMenuEvent($event)"
        >
          <span class="icon"><i class="icofont-mega-phone"></i></span>
          <span class="link">Marketing</span>
        </a>
        <ul [class]="setMenuClass('marketing')">
          <li>
            <a
              class="dashboard_sidebar-link"
              class="dashboard_sidebar-link"
              (click)="comingSoon()"
              >Active</a
            >
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Draft</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Inactive</a>
          </li>
        </ul>
      </li>
      <!-- Hidden as of now -->
      <li
        class="dashboard_sidebar-itemdropdown d-none"
        id="settings-dd"
        (click)="expandMenu('settings')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['settings']"
          (click)="activeMenuEvent($event)"
          ><span class="icon"><i class="icofont-ui-settings"></i></span>
          <span class="link">Setting</span>
        </a>
        <ul [class]="setMenuClass('settings')">
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Active</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Draft</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Inactive</a>
          </li>
        </ul>
      </li>
      <!-- Hidden as of now -->
      <li
        class="dashboard_sidebar-item dropdown d-none"
        id="support-dd"
        (click)="expandMenu('support')"
      >
        <a
          class="dashboard_sidebar-link"
          [routerLink]="['support']"
          (click)="activeMenuEvent($event)"
          ><span class="icon"><i class="icofont-support"></i></span>
          <span class="link">Support</span>
        </a>
        <ul [class]="setMenuClass('support')">
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Active</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Draft</a>
          </li>
          <li>
            <a class="dashboard_sidebar-link" (click)="comingSoon()">Inactive</a>
          </li>
        </ul>
      </li>
    </ul>
  
    <div class="dashboard_sidebar-logoutBtn">
      <a class="action" title="Logout" (click)="logout(); onNavLinkClick()" 
        ><span class="icon"><i class="icofont-sign-out"></i></span><span>Log out</span></a
      >
    </div>
  </div>
  