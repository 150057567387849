import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../core/_store/app.state';

import { ConfirmPasswordValidator } from '../../auth-common/confirm-password.validator';
import { RegisterUser } from '../../../core/_models/register.user';

import { AppConstants } from 'src/app/core/_utils/appconstants';
import { RegistrationService } from 'src/app/core/_auth/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/_auth/services/auth.service';
import { TokenResponse } from 'src/app/core/_models/token.response';
import { CookieService } from 'ngx-cookie-service';
import { SocketService } from 'src/app/services/socket.service';
import { CommunicationService } from '../../../services/communication.service';

@Component({
  selector: 'app-business-signin',
  templateUrl: './business-signin.component.html',
  styleUrls: ['./business-signin.component.scss'],
})
export class BusinessSignInComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;

  loginState: boolean = false;
  showResetPwd: boolean = false;
  public screenWidth: any;
  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private router: Router,
    private auth: AuthService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private ws: SocketService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit(): void {
    if (this.appState.get(AppConstants.USERNAME)) {
      this.loginState = true;
    } else {
      this.loginState = false;
    }
    this.screenWidth = window.innerWidth;
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      username: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ]),
      ],
      password: [
        null,
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.loginForm.controls[controlName];

    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  submit() {
    const controls = this.loginForm.controls;

    // check form and show errors in case there is any
    if (this.loginForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }

    const authData = {
      username: controls.username.value,
      password: controls.password.value,
    };

    this.auth.login(authData.username, authData.password).subscribe(
      (resp) => {
        let tokenResp: TokenResponse = resp;
        if (resp.userRole == 'SITE_PARTNER') {
          this.loginSuccess(tokenResp, authData.username);
        } else {
          this.toastr.warning(
            'Please Enter the Credentials for Business',
            'WARNING'
          );
        }
      },
      (err) => {
        console.log('login ERROR', err);
        if (err.status == 401) {
          this.toastr.error('Invalid credentials', 'ERROR');
        }
        // else if (err.status == 500) {
        //   this.toastr.error(err.error.message, 'ERROR');
        //   if (
        //     err.error.message == 'User is not verified' &&
        //     this.openVerifyEmail
        //   ) {
        //     this.appState.set(AppConstants.VERIFY_EMAIL, '');
        //     this.appState.set(AppConstants.VERIFY_USERNAME, authData.username);
        //     this.openVerifyEmail();
        //   }
        // }
      }
    );
  }

  onForgotPwdClick() {
    this.showResetPwd = true;
  }

  onResetPwdCancel() {
    this.showResetPwd = false;
  }

  loginSuccess(tokenResp: TokenResponse, username: string) {
    // TODO: define common success login code here
    this.cookieService.set(
      AppConstants.COOKIE_TOKEN,
      tokenResp.token,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USERNAME,
      username,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USER_ID,
      tokenResp.userId,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.cookieService.set(
      AppConstants.USER_ROLE,
      tokenResp.userRole,
      AppConstants.TOKEN_VALIDITY,
      '/'
    );
    this.appState.set(AppConstants.COOKIE_TOKEN, tokenResp.token);
    this.router.navigateByUrl('/partner');
    // this.loginSuccessEvent.emit(username);

    this.ws.initializeWebSocket();
    this.ws.subscribeToListenMessage();
  }

  onSignupButtonClick() {
    this.communicationService.notifyLoginSignUpButtonClicked();
    this.router.navigateByUrl('/business/signup');
  }

  ngOnDestroy() {}
}
