import { Component, OnInit, HostListener, Input } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/core/_auth/services/auth.service';
import { AuthAccountService } from 'src/app/core/_services/auth.account.service';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { CommunicationService } from 'src/app/services/communication.service';
import { SocketService } from 'src/app/services/socket.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-header-customer-profile',
  templateUrl: './header-customer-profile.component.html',
  styleUrls: ['./header-customer-profile.component.scss'],
})
export class HeaderCustomerProfileComponent implements OnInit {
  chatToggle: boolean;
  notifWindowToggle: boolean = false;
  public screenWidth: any;
  public notificationCount: number;
  currentUserPhoto: any = "assets/images/avatar-user.svg";
  constructor(
    private authAccountService: AuthAccountService,
    private ws: SocketService,
    private communicationService: CommunicationService,
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    let username = this.cookieService.get(AppConstants.USERNAME);
    this.authService.getUserDetails(username).subscribe(
      (resp) => {
        this.currentUserPhoto = resp.photopath;
      });
    this.screenWidth = window.innerWidth;
    this.ws.notificationDotChanges$.subscribe((notification: number) => {
      this.notificationCount = notification;
    });
    this.communicationService.getContactSellerButtonClickEvent().subscribe(resp => {
      this.chatToggle = true;

      setTimeout(() => {
        /* Trigger message room redirection */
        this.communicationService.notifyMessageRoomRedirection(resp);  
      }, 1000);
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  logout() {
    Swal.fire({
      title: 'Logout as Customer?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#ff6d41',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout',
    }).then((result) => {
      if (result.isConfirmed) {
        this.authAccountService.clearUserSession();
        window.location.href = '/';
        // this.router.navigateByUrl("/");
      }
    });
  }
  toggleChatWindow() {
    this.chatToggle = !this.chatToggle;
  }
  onChatClose($event) {
    this.chatToggle = false;
  }
  toggleNotifWindow() {
    this.notifWindowToggle = !this.notifWindowToggle;
  }
  onNotificationClose() {
    this.notifWindowToggle = false;
  }
}
