import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthAccountService } from 'src/app/core/_services/auth.account.service';
import { LayoutService } from '../../common/layout.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/services/socket.service';
import { RegisterUser } from 'src/app/core/_models/register.user';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';

@Component({
  selector: 'app-partner-header',
  templateUrl: './partner-header.component.html',
  styleUrls: ['./partner-header.component.scss'],
})
export class PartnerHeaderComponent implements OnInit {
  partnerChatToggle: boolean = false;
  notifyWindowToggle: boolean = false;
  public notificationCount: number;
  public fullName: string;
  public address: string;
  @Output('onUserLogin') onUserLoginEvent = new EventEmitter<string>(); // emits user role
  @Input('userDetails') userDetails: RegisterUser;

  constructor(
    private authAccountService: AuthAccountService,
    private layoutService: LayoutService,
    private router: Router,
    private ws: SocketService
  ) {}

  ngOnInit(): void {
    this.ws.notificationDotChanges$.subscribe((notification: number) => {
      this.notificationCount = notification;
    })

    
  }

  toggleMenu() {
    this.layoutService.toggleMenu();
  }

  toggleChatWindow() {
    this.partnerChatToggle = !this.partnerChatToggle;
  }

  onPartnerChatClose($event) {
    this.partnerChatToggle = false;
  }

  navigateToCoupons() {
    this.router.navigateByUrl('/partner');
  }

  toggleNotifyWindow() {
    this.notifyWindowToggle = !this.notifyWindowToggle;
  }

  ngOnChanges() {
    if(this.userDetails){
      this.fullName = CommonsUtils.firstCharacterUppercase(this.userDetails.firstname) + ' ' + CommonsUtils.firstCharacterUppercase(this.userDetails.lastname);
      this.address = "";
      if(this.userDetails?.addressLine1){
        this.address = this.userDetails?.addressLine1
      }
      if(this.userDetails?.addressLine2){
        this.address += ', ' + this.userDetails?.addressLine2;
      }
      
    }
  }
  
}
