<div class="cl partner-header-wrap">
  <div class="partner-header-inner left">
    <!-- <button type="button" class="menuToggle" (click)="toggleMenu()" title="Toggle Menu">
      <i class="fa fa-bars"></i>
    </button> -->
    <div class="d-flex flex-column text-white pt-4" *ngIf="this.userDetails">
      <h4>Hi, {{ userDetails.username }}</h4>
      <span>{{ userDetails.businessName }}</span>
    </div>
  </div>
  <div class="partner-header-inner right">
    <ul class="actions-list">
      <li class="actions-item">
        <a class="header-action" (click)="toggleChatWindow()" title="Chat">
          <span class="icon" [ngClass]="{ 'shift-right': notificationCount }">
            <div class="icon-wrapper"><i class="fa fa-comments"></i></div>
            <span class="notification-dot" *ngIf="notificationCount">{{ notificationCount }}</span>
          </span>
          <!-- Chat -->
        </a>
      </li>
      <li class="actions-item">
        <a class="header-action" (click)="toggleNotifyWindow()" title="Notifications">
          <span class="icon">
            <div class="icon-wrapper"><i class="fa fa-bell-o"></i></div>
            <!-- <span class="notification-dot" *ngIf="notificationCount">{{notificationCount}}</span> -->
          </span>
        </a>
        <ng-container *ngIf="notifyWindowToggle">
          <div class="actions-item-modal">
            <app-common-notifications></app-common-notifications>
          </div>
        </ng-container>
      </li>
      <li class="nav-item profile-dropdown">
        <a class="nav-link" role="button" aria-haspopup="true" aria-expanded="false" title="Profile">
          <!-- <i class="fa fa-user" aria-hidden="true"></i> -->
          <img
            [src]="userDetails.photopath"
            class="avatarImg"    
            alt="QR code"
          />
        </a>
      </li>
      <li class="nav-item user-info">
        <div class="d-flex flex-column text-white">
          <h5>{{ fullName }}</h5>
          <span>{{ address }}</span>
        </div>
      </li>
      <li class="nav-item">
        <button
          class="navbar-toggler"
          id="navbarToggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i class="fa fa-bars ham-icon"></i>
          <i class="fa fa-times close-icon"></i>
        </button>
      </li>
    </ul>
  </div>
</div>

<!-- Prtner Chat Window -->
<ng-container *ngIf="partnerChatToggle">
  <app-customer-chat (close)="onPartnerChatClose($event)"></app-customer-chat>
</ng-container>
<!-- Prtner Chat Window -->

<div class="header_sub-wrapper-inner">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="collapse navbar-collapse" id="navbarNav">
      <ng-container *ngIf="screenWidth <= 991">
        <partner-mobile-navigation
          [fullName]="fullName"
          [address]="address"
          (linkClicked)="closeNavbarNav()"
        ></partner-mobile-navigation>
      </ng-container>
    </div>
  </nav>
</div>
