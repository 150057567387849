<app-header></app-header>

<div class="home-banner-container">
  <app-home-banner></app-home-banner>
</div>

<!-- <div class="ads-wrapper">
  <div class="ads-wrapper-inner">
    <div class="container">
      <div class="ads">
        <div class="ezoic-basic-ad ads-placeholder"></div>
      </div>
    </div>
  </div>
</div> -->
<!--This component has coupons-->
<app-top-deals></app-top-deals>

<!-- <div class="ads-wrapper">
  <div class="ads-wrapper-inner">
    <div class="container">
      <div class="ads text-center">
        <div class="ezoic-basic-ad ads-placeholder"></div>
      </div>
    </div>
  </div>
</div> -->

<!-- Popular-stores -->

<!-- <app-popular-stores></app-popular-stores> -->
<!-- Popular-stores -->

<!-- <div class="ads-wrapper">
  <div class="ads-wrapper-inner">
    <div class="container">
      <div class="ads">
        <img src="../../../../assets/images/g-ads.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div> -->

<div style="height: 40px"></div>

<!-- <app-deal-categories></app-deal-categories> -->

<!--This component has deals-->
<app-deals-of-day></app-deals-of-day>

<!-- <div class="ads-wrapper">
  <div class="ads-wrapper-inner">
    <div class="container">
      <div class="ads">
        <img src="../../../../assets/images/g-ads.png" alt="" class="img-fluid">
      </div>
    </div>
  </div>
</div> -->
<div class="container w-100 p-4">
  <app-promotion></app-promotion>
</div>

<app-deal-categories></app-deal-categories>

<!-- <div class="container w-100 p-4 d-none">
  <div class="row d-flex align-items-center justify-content-center">
    <div class="col-md-4">
      <div class="ads">
        <div class="ezoic-basic-ad" style="height:250px;"></div>
      </div>
    </div>
    <div class="col-md-8"> -->
<!-- <app-newsletter></app-newsletter> -->
<!-- <div class="ezoic-basic-ad" style="height:250px;"></div>
    </div>
  </div>
</div> -->
<app-footer *ngIf="!loginState"></app-footer>
