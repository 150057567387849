import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/_auth/services/auth.service';
import { ConfirmPasswordValidator } from '../confirm-password.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @Output('onCancel') onCancelEvent = new EventEmitter<any>();
  @Input() currentPage: string = 'email'; // Add this line
  @Output() currentPageChange = new EventEmitter<string>();
  @Output() resetPasswordToRedirect = new EventEmitter<boolean>();

  resetPwdForm: FormGroup;
  username: string;
  tfa_code: string;
  // currentPage: string = 'email';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.resetPwdForm = this.fb.group(
      {
        username: [null, Validators.compose([Validators.required])],
        email: [
          null,
          Validators.compose([Validators.required, Validators.email]),
        ],
        code: [null, Validators.compose([Validators.required])],
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.pattern(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/
            ),
          ]),
        ],
        confirmPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  // Call this method to update the currentPage and emit the change
  updateCurrentPage(newPage: string) {
    this.currentPage = newPage;
    this.currentPageChange.emit(this.currentPage); // Emit the updated value
  }

  get passwordFormField() {
    return this.resetPwdForm.get('password');
  }

  // Handle email submission and proceed to the next step
  onSubmitEmail() {
    const controls = this.resetPwdForm.controls;

    // check form and show errors in case there is any
    if (this.resetPwdForm.get('email').invalid) {
      controls['email'].markAsTouched();
      return;
    }
    this.toastr.info('Sending verfication code to your email');
    // backend to send the email
    this.authService.sendEmailOnForgotPassword(this.resetPwdForm.get('email').value).subscribe((resp) => {
      this.username = resp.username;
      this.toastr.success('verification code sent successfully');
    },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );

    // Simulate sending an email or API call here
    this.updateCurrentPage('code');
  }

  // Handle code submission and proceed to the next step
  onSubmitCode() {
    const controls = this.resetPwdForm.controls;

    // check form and show errors in case there is any
    if (this.resetPwdForm.get('code')?.invalid) {
      controls['code'].markAsTouched();
      return;
    }
    
    this.authService.verifyCodeEmailVerify
    (
      this.username, 
      this.resetPwdForm.get('code')?.value
    ).subscribe((resp) => {
      this.tfa_code = this.resetPwdForm.get('code')?.value;
      this.updateCurrentPage('newPassword');
    },
    (err)=>{
      if(err.status = 403){
        this.toastr.error("Incorrect Verification code!");
      }
      else{
        this.toastr.error("Something is wrong");
      }
    });
    
    
  }

  // Handle new password submission
  onSubmitNewPassword() {
    const { password, confirmPassword } = this.resetPwdForm.value;
    const controls = this.resetPwdForm.controls;
    
    // check form and show errors in case there is any
    if (this.resetPwdForm.get("password").invalid ||
       this.resetPwdForm.get("confirmPassword").invalid || 
       password !== confirmPassword) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    
    this.submit();
    
    // this.currentPage = 'email'; // Or redirect to login page
    
  }

  // Navigate to the previous step
  previousStep() {
    if (this.currentPage === 'newPassword') {
      this.currentPage = 'code';
    } else if (this.currentPage === 'code') {
      this.currentPage = 'email';
    }
  }

  send2FACode() {
    let user = this.resetPwdForm.value;
    this.authService.sendCodeEmailVerify(user.username, user.email).subscribe(
      (resp) => {
        this.toastr.success('Verification code sent on your email.');
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  submit() {
    let value = this.resetPwdForm.value;
    this.authService
      .resetUserPwd(this.username, value.password, this.tfa_code)
      .subscribe(
        (resp) => {
          this.toastr.success(
            'Password has been reset. Now please login with new password.'
          );
          this.updateCurrentPage('passwordChangeSuccess');
          // this.onCancelEvent.emit(undefined);
        },
        (err) => {
          this.toastr.warning('Code verfication failed.', '');
        }
      );
  }

  getUsernameByEmail() {
    if (this.resetPwdForm.value.email) {
      this.authService
        .getUserInfoByEmail(this.resetPwdForm.value.email)
        .subscribe(
          (resp) => {
            this.resetPwdForm.controls.username.patchValue(resp.username);
          },
          (err) => {
            this.toastr.warning('NO user found with this email.');
          }
        );
    } else {
      this.toastr.warning('Please put email first to get username');
    }
  }

  openLoginForm() {
    this.resetPasswordToRedirect.emit();
  }

  cancel() {
    this.onCancelEvent.emit(undefined);
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.resetPwdForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
