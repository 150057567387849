import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerChatComponent } from './customer-chat/customer-chat.component';
import { LayoutService } from './layout.service';
import { ScrollToBottomDirective } from './scroll-to-bottom.directive';
import { AppHttpLoaderComponent } from './app-http-loader/app-http-loader.component';
import { CommonNotificationsComponent } from './common-notifications/common-notifications.component';
import { SearchPageComponent } from './common-search-page/app-common-search-page';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ErrorComponent } from './error/error.component';

@NgModule({
  declarations: [ ScrollToBottomDirective, CustomerChatComponent, AppHttpLoaderComponent, CommonNotificationsComponent, SearchPageComponent, ErrorComponent ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule
  ],
  exports: [ CustomerChatComponent, AppHttpLoaderComponent, CommonNotificationsComponent, SearchPageComponent ],
  providers: [ LayoutService ]
})
export class CommonModuleCustom { }
