<div [ngSwitch]="currentPage">
  <!-- Step 1: Enter Email -->
  <div *ngSwitchCase="'email'" class="form-step">
    <form [formGroup]="resetPwdForm" (ngSubmit)="onSubmitEmail()">
      <div class="form-group">
        <label>Email</label>
        <input
          name="email"
          type="email"
          class="form-control"
          formControlName="email"
          placeholder="Enter Your Email"
          [ngClass]="{ 'is-invalid': hasError('email', 'required') }"
        />

        <mat-error *ngIf="hasError('email', 'required')">
          Email is required
        </mat-error>

        <mat-error *ngIf="hasError('email', 'email')">
          Invalid Email address
        </mat-error>
      </div>

      <div class="btnWrap">
        <button type="submit" class="submit-btn">Reset Password</button>
      </div>
    </form>
  </div>

  <!-- Step 2: Verification Code -->
  <div *ngSwitchCase="'code'" class="form-step">
    <form [formGroup]="resetPwdForm" (ngSubmit)="onSubmitCode()">
      <div class="form-group">
        <label>Code</label>
        <input
          name="code"
          class="form-control"
          placeholder="Enter Code"
          formControlName="code"
          [ngClass]="{ 'is-invalid': hasError('code', 'required') }"
        />
        <mat-error *ngIf="hasError('code', 'required')">
          Two-Factor Code is required, check your email for it.
        </mat-error>
      </div>

      <p
        style="
          color: #575757;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
        "
      >
        Didn't receive the email?
        <span class="resendLink common-gradient-text w-fit-content">Click to resend</span>
      </p>

      <div class="btnWrap" style="margin-top: 0px !important">
        <button type="submit" class="submit-btn">Confirm</button>
      </div>
    </form>
  </div>

  <!-- Step 3: Choose New Password -->
  <div *ngSwitchCase="'newPassword'" class="form-step">
    <form [formGroup]="resetPwdForm" (ngSubmit)="onSubmitNewPassword()">
      <div class="form-group">
        <label>New Password</label>
        <input
          type="password"
          class="form-control"
          placeholder="Enter New Password"
          formControlName="password"
          [ngClass]="{ 'is-invalid': hasError('password', 'required') }"
        />

        <mat-error *ngIf="hasError('password', 'required')">
          Password is required.
        </mat-error>
      </div>
      <div class="form-group">
        <label>Confirm Password</label>
        <input
          type="password"
          class="form-control"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
          [ngClass]="{ 'is-invalid': hasError('confirmPassword', 'required') }"
        />
        <mat-error *ngIf="hasError('confirmPassword', 'required')">
          Confirm Password is required.
        </mat-error>

        <mat-error *ngIf="hasError('confirmPassword', 'minlength')">
          Confirm Password should be minimum 6 character (alpha-numeric)
        </mat-error>
        <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">
          Confirm Password can be maximum 100 character (alpha-numeric)
        </mat-error>
        <mat-error *ngIf="hasError('confirmPassword', 'ConfirmPassword')">
          Password does not match
        </mat-error>
      </div>

      <!-- *ngIf="passwordFormField?.dirty" -->
      <div class="text-sm mt-3">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            class="flex items-center"
            [ngClass]="{
              'is-success': passwordFormField?.value?.match('^(?=.*[A-Z])'),
              'is-invalid': !passwordFormField?.value?.match('^(?=.*[A-Z])')
            }"
          >
            <i
              class="fa"
              [ngClass]="
                passwordFormField?.value?.match('^(?=.*[A-Z])')
                  ? 'fa-check-circle is-success'
                  : 'fa-times-circle is-invalid'
              "
            ></i>
            <span class="ml-2">One uppercase character</span>
          </span>

          <span
            class="flex items-center"
            [ngClass]="{
              'is-success': passwordFormField?.value?.match('.{8,}'),
              'is-invalid': !passwordFormField?.value?.match('.{8,}')
            }"
          >
            <i
              class="fa"
              [ngClass]="
                passwordFormField?.value?.match('.{8,}')
                  ? 'fa-check-circle is-success'
                  : 'fa-times-circle is-invalid'
              "
            ></i>
            <span class="ml-2">8 character minimum</span>
          </span>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <span
            class="flex items-center"
            [ngClass]="{
              'is-success': passwordFormField?.value?.match('(?=.*[a-z])'),
              'is-invalid': !passwordFormField?.value?.match('(?=.*[a-z])')
            }"
          >
            <i
              class="fa"
              [ngClass]="
                passwordFormField?.value?.match('(?=.*[a-z])')
                  ? 'fa-check-circle is-success'
                  : 'fa-times-circle is-invalid'
              "
            ></i>
            <span class="ml-2">One lowercase character</span>
          </span>

          <span
            class="flex items-center"
            [ngClass]="{
              'is-success': passwordFormField?.value?.match('(?=.*[!@#$%^&*])'),
              'is-invalid': !passwordFormField?.value?.match('(?=.*[!@#$%^&*])')
            }"
          >
            <i
              class="fa"
              [ngClass]="
                passwordFormField?.value?.match('(?=.*[!@#$%^&*])')
                  ? 'fa-check-circle is-success'
                  : 'fa-times-circle is-invalid'
              "
            ></i>
            <span class="ml-2">One special character</span>
          </span>
        </div>

        <span
          class="flex items-center"
          [ngClass]="{
            'is-success': passwordFormField?.value?.match('(.*[0-9].*)'),
            'is-invalid': !passwordFormField?.value?.match('(.*[0-9].*)')
          }"
        >
          <i
            class="fa"
            [ngClass]="
              passwordFormField?.value?.match('(.*[0-9].*)')
                ? 'fa-check-circle is-success'
                : 'fa-times-circle is-invalid'
            "
          ></i>
          <span class="ml-2">One number</span>
        </span>
      </div>

      <div class="btnWrap">
        <button type="submit" class="submit-btn">Submit</button>
      </div>
    </form>
  </div>

  <!-- Step 4: Thank you -->

  <div *ngSwitchCase="'passwordChangeSuccess'" class="form-step">
    <div style="display: flex; align-items: center; justify-content: center">
      <img
        class="logo"
        src="assets/images/Login/passwordChangeSuccessImage.png"
        alt="Logo"
        style="width: 80px; height: 80px; margin: 10px"
      />
    </div>

    <h4 class="passwordChangeSuccess">
      Thank you! Your password has been reset. You can now log in.
    </h4>

    <div class="btnWrap">
      <button class="submit-btn" (click)="openLoginForm()">Log in</button>
    </div>
  </div>
</div>
