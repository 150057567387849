import { Component, OnInit, OnDestroy, Renderer2, PLATFORM_ID, Inject, ElementRef } from "@angular/core";
import { DealDetail } from "src/app/core/_models/deal.detail";
import { OwlOptions } from "ngx-owl-carousel-o";
import { ToastrService } from "ngx-toastr";
import { AppState } from "src/app/core/_store/app.state";
import { AppConstants } from "src/app/core/_utils/appconstants";
import { Router } from "@angular/router";
import { CouponService } from "src/app/core/_services/coupon.service";
import { CommonsUtils } from "src/app/core/_utils/commons.utils";
import EmblaCarousel from "embla-carousel";
import { disablePrevNextBtns, setupPrevNextBtns } from "../../theme/embla-carousal/prevNextButtons";
import { setupRTLDirectionIfNeeded } from "../../theme/embla-carousal/directionRTL";
import { isPlatformBrowser } from "@angular/common";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";

@Component({
  selector: "app-top-deals",
  templateUrl: "./top-deals.component.html",
  styleUrls: ["./top-deals.component.scss"],
})
export class TopDealsComponent implements OnInit, OnDestroy {
  coupons: any[];
  groupedCoupons: any[];
  dealCaraouselOptions: OwlOptions;
  MINIMUM_COUPONS: number;
  private isBrowser: boolean;
  public embla: any;
  noImageAvailable: string;
  public isloading: boolean = true;

  constructor(
    private appState: AppState,
    private toastr: ToastrService,
    private router: Router,
    private couponService: CouponService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) platformId: Object,
    private elem: ElementRef
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.coupons = [];
    this.groupedCoupons = [];
    this.MINIMUM_COUPONS = 4;
    this.noImageAvailable = "../../../../assets/images/no-image.png";
  }

  ngOnInit(): void {
    this.getAllCoupons();
  }

  ngAfterViewInit(): void {}

  initializeEmblaCarousal() {
    setTimeout(() => {
      const wrap = this.renderer.selectRootElement(".embla-top-deals", true);

      if (wrap && this.isBrowser) {
        const viewPort = wrap.querySelector(".embla__viewport")!;
        const prevBtn = wrap.querySelector(".embla__button--prev");
        const nextBtn = wrap.querySelector(".embla__button--next");
        this.embla = EmblaCarousel(
          viewPort as HTMLElement,
          {
            align: "start",
          },
          [WheelGesturesPlugin()]
        );

        const disablePrevAndNextBtns = disablePrevNextBtns(prevBtn, nextBtn, this.embla);

        setupPrevNextBtns(prevBtn, nextBtn, this.embla);
        setupRTLDirectionIfNeeded(this.embla, wrap);

        this.embla.on("select", disablePrevAndNextBtns);
        this.embla.on("init", disablePrevAndNextBtns);

        this.embla.on("slidesInView", this.logSlidesInView.bind(this));
      }
    }, 1000);
  }

  currentPage = 0;
  currentIndex = 0;
  isCouponsFinished = false;
  isInitialLoad = true;
  getAllCoupons() {
    this.isloading = true;

    this.couponService.getPopularCouponOffers(this.currentPage, 24, "", "", true).subscribe((resp) => {
      const newCoupons = resp.coupons;

      if (newCoupons.length == 0) {
        this.isCouponsFinished = true;
      }

      for (let i = 0; i < newCoupons.length; i += 3) {
        const group = [newCoupons[i]];
        if (i + 1 < newCoupons.length) {
          group.push(newCoupons[i + 1]);
        }
        if (i + 2 < newCoupons.length) {
          group.push(newCoupons[i + 2]);
        }
        this.groupedCoupons.push(group);
      }

      this.isloading = false;

      if (this.isInitialLoad) {
        this.isInitialLoad = false;
        this.initializeEmblaCarousal();
      }
    });
  }

  getEmblaInstance() {
    return this.embla;
  }

  logSlidesInView(emblaApi: any): void {
    const slidesInView = emblaApi.slidesInView();
    const totalSlides = this.groupedCoupons.length * 3;

    const lastVisibleSlideIndex = slidesInView[slidesInView.length - 1];
    const threshold = totalSlides - 6;

    if (lastVisibleSlideIndex * 3 >= threshold && !this.isloading && !this.isCouponsFinished) {
      this.currentPage++;
      this.getAllCoupons();
    }
  }

  setAllCaraouselOptions() {
    this.dealCaraouselOptions = {
      loop: true,
      dots: false,
      margin: 16,
      nav: true,
      navSpeed: 700,
      navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>'],
      responsive: {
        0: {
          items: 1,
          dots: true,
        },
        576: {
          items: 2,
        },
        768: {
          items: 3,
        },
        1200: {
          items: 4,
        },
        1400: {
          items: 5,
        },
      },
    };
  }

  calcDiscount(deal: DealDetail): number {
    let p1 = deal.products[0].normalPrice;
    let p2 = deal.products[0].dealPrice;
    let discount = p1 - p2;
    return discount;
  }

  truncate(value: string, length: number): string {
    return CommonsUtils.truncate(value, length);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  getThisCoupon(coupon) {
    if (!this.loginState()) {
      window.scroll(0, 0);
      this.toastr.error("To activate this Coupon you must sign in as consumer.", "Sign In");
    } else {
      if (coupon.weblink) {
        window.open(coupon.weblink, '_blank'); 
      } else {
        this.router.navigateByUrl('/customer/coupons-browse');}
    }
  }

  loginState() {
    if (this.appState.get(AppConstants.USERNAME)) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.embla) {
      this.embla.destroy();
    }
  }
}
