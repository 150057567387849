<div class="navbar-mob-container">
    
    <div *ngIf="!login_state" class="d-flex justify-content-center sign-in-container" type="button" data-toggle="modal" (click)="navigateToLoginPopup(true)">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 24C5.3724 24 0 18.6276 0 12C0 5.3724 5.3724 0 12 0C18.6276 0 24 5.3724 24 12C24 18.6276 18.6276 24 12 24ZM6.0156 19.5072C7.71426 20.8652 9.82521 21.6035 12 21.6C14.364 21.6 16.5276 20.7456 18.2004 19.3296C17.4189 18.5277 16.4845 17.8907 15.4524 17.4562C14.4204 17.0217 13.3118 16.7986 12.192 16.8C11.0311 16.7987 9.8826 17.0386 8.81936 17.5047C7.75611 17.9707 6.80134 18.6526 6.0156 19.5072ZM4.3392 17.784C5.34774 16.7137 6.5648 15.8613 7.91537 15.2793C9.26593 14.6973 10.7214 14.398 12.192 14.4C13.61 14.3982 15.0144 14.6764 16.3245 15.2189C17.6346 15.7613 18.8247 16.5572 19.8264 17.5608C20.8537 16.1149 21.4604 14.4128 21.5791 12.6431C21.6978 10.8734 21.3239 9.10546 20.499 7.53535C19.674 5.96523 18.4302 4.65435 16.9056 3.74813C15.3809 2.84192 13.635 2.37581 11.8615 2.4015C10.0881 2.4272 8.35639 2.9437 6.85862 3.89371C5.36086 4.84371 4.15555 6.19008 3.37643 7.78344C2.59731 9.3768 2.27483 11.1549 2.44478 12.9203C2.61472 14.6858 3.27045 16.3685 4.3392 17.784ZM12 13.2C10.727 13.2 9.50606 12.6943 8.60589 11.7941C7.70571 10.8939 7.2 9.67304 7.2 8.4C7.2 7.12696 7.70571 5.90606 8.60589 5.00589C9.50606 4.10571 10.727 3.6 12 3.6C13.273 3.6 14.4939 4.10571 15.3941 5.00589C16.2943 5.90606 16.8 7.12696 16.8 8.4C16.8 9.67304 16.2943 10.8939 15.3941 11.7941C14.4939 12.6943 13.273 13.2 12 13.2ZM12 10.8C12.6365 10.8 13.247 10.5471 13.6971 10.0971C14.1471 9.64697 14.4 9.03652 14.4 8.4C14.4 7.76348 14.1471 7.15303 13.6971 6.70294C13.247 6.25286 12.6365 6 12 6C11.3635 6 10.753 6.25286 10.3029 6.70294C9.85286 7.15303 9.6 7.76348 9.6 8.4C9.6 9.03652 9.85286 9.64697 10.3029 10.0971C10.753 10.5471 11.3635 10.8 12 10.8Z" fill="black"/>
        </svg>
        <span class="ml-1">Sign In</span>
    </div>
    <div *ngIf="login_state" class="d-flex pb-2 flex-column align-items-center justify-content-between">
        <h3>Welcome</h3>

        <h4 style="color: #ed400c;">{{fullName}}</h4>
    </div>
    <div class="navbar-logo d-flex align-items-center justify-content-center">
        <img class="logo" src="assets/images/logos/locoldeal-logo.svg" alt="Logo" style="height: 25px;" />
    </div>
    <ul class="navbar-nav mt-3">
        <li class="nav-item">
            <a class="nav-link" routerLink="/customer/product-search">Deals Of The Day</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/customer/store-list">Stores</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" routerLink="/business/signin">Business login</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="https://locoldeal.com/blog">Blog</a>
        </li>
    </ul>

    <ul class="navbar-nav">
        <li class="nav-item" style="color: #F6410A;">
            <h5> Popular Categories </h5>
        </li>
        <li *ngFor="let cat of displayedCategories" class="nav-item">
            <a class="nav-link" title="{{ cat.categoryName }}" (click)="navigagateToDeal(cat.categoryName)">
                <span>
                    {{ cat.categoryName }}  
                </span>
            </a>
        </li>

        <li class="nav-item mt-3" style="color: black;" *ngIf="!showAll" (click) = "showAllCategories()">
            <h6><u> Browse All Categories </u> </h6>
        </li>
    </ul>

    <ul *ngIf="!login_state" class="navbar-nav">
        <li class="nav-item">
            <h5>Not a member? sign up</h5>  
        </li>
        <li class="nav-item" >
            <a class="nav-link" type="button" data-toggle="modal" (click)="navigateToSignupPopup()">
                <span>
                    As a Customer
                </span>
            </a>
        </li>
        <li class="nav-item" >
            <a class="nav-link" routerLink="/business/signup">
                <span>
                    As a Business
                </span>
            </a>
        </li>
    </ul>

    <ul *ngIf="login_state" class="navbar-nav">
        <a class="nav-link" routerLink="/customer/user-query" title="Customer Support">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.938 8.00088H21C21.5304 8.00088 22.0391 8.21159 22.4142 8.58666C22.7893 8.96174 23 9.47044 23 10.0009V14.0009C23 14.5313 22.7893 15.04 22.4142 15.4151C22.0391 15.7902 21.5304 16.0009 21 16.0009H19.938C19.6942 17.9342 18.7533 19.7121 17.2917 21.0009C15.8302 22.2897 13.9486 23.0008 12 23.0009V21.0009C13.5913 21.0009 15.1174 20.3687 16.2426 19.2435C17.3679 18.1183 18 16.5922 18 15.0009V9.00088C18 7.40958 17.3679 5.88345 16.2426 4.75824C15.1174 3.63302 13.5913 3.00088 12 3.00088C10.4087 3.00088 8.88258 3.63302 7.75736 4.75824C6.63214 5.88345 6 7.40958 6 9.00088V16.0009H3C2.46957 16.0009 1.96086 15.7902 1.58579 15.4151C1.21071 15.04 1 14.5313 1 14.0009V10.0009C1 9.47044 1.21071 8.96174 1.58579 8.58666C1.96086 8.21159 2.46957 8.00088 3 8.00088H4.062C4.30603 6.06777 5.24708 4.29015 6.70857 3.00155C8.17007 1.71296 10.0516 1.00195 12 1.00195C13.9484 1.00195 15.8299 1.71296 17.2914 3.00155C18.7529 4.29015 19.694 6.06777 19.938 8.00088ZM3 10.0009V14.0009H4V10.0009H3ZM20 10.0009V14.0009H21V10.0009H20ZM7.76 15.7859L8.82 14.0899C9.77308 14.6869 10.8754 15.0027 12 15.0009C13.1246 15.0027 14.2269 14.6869 15.18 14.0899L16.24 15.7859C14.9693 16.582 13.4995 17.0032 12 17.0009C10.5005 17.0032 9.03074 16.582 7.76 15.7859Z" fill="black"/>
                </svg>
                
            <span class="ml-1">
                Customer Services
            </span>
        </a>
        <li class="nav-item" >
            <a class="nav-link" (click)="logout()">
                <svg width="24" height="24" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.75C0 0.784 0.784 0 1.75 0H7.25C7.44891 0 7.63968 0.0790177 7.78033 0.21967C7.92098 0.360322 8 0.551088 8 0.75C8 0.948912 7.92098 1.13968 7.78033 1.28033C7.63968 1.42098 7.44891 1.5 7.25 1.5H1.75C1.6837 1.5 1.62011 1.52634 1.57322 1.57322C1.52634 1.62011 1.5 1.6837 1.5 1.75V19.25C1.5 19.388 1.612 19.5 1.75 19.5H7.25C7.44891 19.5 7.63968 19.579 7.78033 19.7197C7.92098 19.8603 8 20.0511 8 20.25C8 20.4489 7.92098 20.6397 7.78033 20.7803C7.63968 20.921 7.44891 21 7.25 21H1.75C1.28587 21 0.840752 20.8156 0.512563 20.4874C0.184375 20.1592 0 19.7141 0 19.25V1.75ZM16.006 11.25L12.706 14.734C12.5746 14.8792 12.5052 15.0701 12.5126 15.2658C12.5201 15.4616 12.6038 15.6466 12.7459 15.7814C12.888 15.9162 13.0773 15.9901 13.2731 15.9872C13.469 15.9843 13.6559 15.9049 13.794 15.766L18.294 11.016C18.4261 10.8767 18.4997 10.692 18.4997 10.5C18.4997 10.308 18.4261 10.1233 18.294 9.984L13.794 5.234C13.6559 5.09508 13.469 5.01569 13.2731 5.01281C13.0773 5.00993 12.888 5.08379 12.7459 5.21858C12.6038 5.35338 12.5201 5.53843 12.5126 5.73416C12.5052 5.92989 12.5746 6.12077 12.706 6.266L16.006 9.75H7.75C7.55109 9.75 7.36032 9.82902 7.21967 9.96967C7.07902 10.1103 7 10.3011 7 10.5C7 10.6989 7.07902 10.8897 7.21967 11.0303C7.36032 11.171 7.55109 11.25 7.75 11.25H16.006Z" fill="black"/>
                    </svg>                    
                <span class="ml-1">
                    Sign Out
                </span>
            </a>
        </li>
    </ul>
</div>