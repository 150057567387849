
<div [ngClass]="isPartner ? 'partner-chat-container':'chat-container'">
    <ul class="list-group">
      
      <li class="list-group-item p-0 chat-body" >
           <div class="chats">
            <ngx-skeleton-loader *ngIf="!notifications"
                count="8"
                [theme]="{ 'border-radius': '0', height: '40px', width: 'rand' }"
            ></ngx-skeleton-loader>
               <ul class="row" *ngIf="notifications">
                   <li class="heading d-flex justify-content-between align-items-center">
                    <div>Notifications</div>
                    <button type="button" id="closeChatButton" class="btn-close" (click)="chatToggle()"></button>
                   </li>
                   <li class="message col-12" *ngFor="let notif of notifications; let i=index;"> 
                      <div class="mesageBox">
                          <div class="text">                              
                              <span *ngIf="notif.notifType == 'CUSTOMER_CHAT_PARTNER'">
                                  There is a new message in chat box</span>
                              <p>{{messageSentTime(notif)}}</p>
                          </div> 
                      </div>                      
                  </li>                
               </ul>
           </div>
  
       </li>
  
    </ul>
  </div>
  