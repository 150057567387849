<section class="deals_collection-wrapper" *ngIf="coupons.length > 0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <h2 class="primary-heading text-center">Locoldeal's <span class="orange-text">Collection</span></h2>
          <owl-carousel-o [options]="dealCaraouselOptions">
            <ng-container *ngFor="let coupon of coupons">
              <ng-template carouselSlide>
                <div class="deals_collection-cardWrapper">
                  <div class="deals_collection-cardWrapper-inner">
                    <div class="deals_collection-card-header">
                      <img width="117px" height="117px" [src]="coupon.brandLogo" [alt]="coupon.brandName" />
                    </div>
                    <div class="deals_collection-card-body">
                      <h5 class="deal-subtitle">{{ coupon.brandName }}</h5>
                      <h3 class="deal-title" style="min-height: 55px">{{coupon.shortTitle}}</h3>
                      <p class="deal-description" style="min-height: 55px; max-height:55px;overflow:hidden;">
                        {{ coupon.longDescription }}
                      </p>
                      <span class="deal-expDate">Expires on {{coupon.validUpTo | date}}</span>
                    </div>
                    <div class="deals_collection-card-footer">
                      <!-- <button type="button" class="custom-btn" title="Get This Coupon" (click)="getThisCoupon()">
                        Get This Coupon
                      </button> -->
                      <button type="button" class="btn custom-btn-sml-orange" title="Get This Coupon" 
                      (click)="getThisCoupon(coupon)">Show Coupon Code</button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </section>
  