import { FormGroup } from '@angular/forms';
import { DealDetail } from '../_models/deal.detail';
import { Product } from '../_models/product';

export class CommonsUtils {
    
  static hasError(form: FormGroup, controlName: string, validationType: string): boolean {
    const control = form.controls[controlName];
    if (!control) {
        return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  static isFormInValid(form: FormGroup) {
    const controls = form.controls;
    if (form.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return true;
    }
    return false;
  }

  public static calcDiscount(product: Product):number {
    if (!product) {
      return 0;
    }
    let p1 = product.normalPrice;
    let p2 = product.dealPrice;
    let discount = p1-p2;
    return Math.floor((discount/p1)*100);
  }

  public static nameInitial(name: string) {
    return name.toUpperCase().charAt(0);
  }

  public static firstCharacterUppercase(name: string){
    return name.charAt(0).toUpperCase() + name.slice(1);
  }
  
  // TODO: remove and get data from server
  public static averageRating(deal: DealDetail) {
    let sum: number = 0;
    deal.ratings.forEach(r => sum += r.rating);
    return sum/deal.ratings.length;
  }

  public static ratingStars(deal: DealDetail) {
    let average = CommonsUtils.averageRating(deal);
    let round = Math.floor(average);
    let stars = [];
    for (let i = 0; i< round; i++) {
      stars.push('fa fa-star');
    }
    if (average > round) {
      stars.push('fa fa-star-half-o');
    }
    return stars;
  }

  
  public static truncate(value: string, length: number): string {
    const elipses = "...";
    if(typeof value === "undefined") return value;
    if(value.length <= length) return value;
    let truncatedText = value.slice(0, length);
    return truncatedText + elipses;
  }

  public static orderStatusClass(status: string) {
    switch(status) {
      case 'CONFIRMED':
      case 'PACKED':
      case 'SHIPPED':
      case 'DELIVERED':
      case 'DELIVERED':
        return 'delivered';
        break;
      case 'RETURN_REQUESTED':
      case 'RETURNED':
      case 'REFUNDED': 
        return 'returned';
        break;
      case 'CANCELLED': 
      case 'PAYMENT_FAILED': 
        return 'cancelled';
        break;
      default:
        break;
    }
  }

  public static getRandomAdsData(): any {
    const adsCategories = ["Beauty", "Kitchen & Houseware", "Gourmet Food", "Health & Personal Care", 
        "Pets", "Prive Video", "Clothing and Shoes", "Grocery", "Home & Garden", "Amazon Fashion"];
    const adsSearchTerm = ["Olay", "leather sofa", "apple cider", "foot massager", 
        "dog food", "latest movies", "Womens top", "Millets Sugar", "green carpet", "Men's Nike"];
    let random = Math.floor(Math.random() * 10);
    return {adsCategory: adsCategories[random], searchTerm: adsSearchTerm[random]};
  }

  public static getAdsCategoryMapping(locolDealCategory: string): string {
    let adsCategory;
    switch(locolDealCategory) {
      case "Beauty":
        adsCategory = "Beauty";
        break;
      case "Shopping/Retail":
        adsCategory = "Kitchen & Houseware";
        break;
      case "Restaurants":
        adsCategory = "Gourmet Food";
        break;
      case "Health":
        adsCategory = "Health & Personal Care";
        break;
      case "Pet Care":
        adsCategory = "Pets";
        break;
      case "Entertainment":
        adsCategory = "Prive Video";
        break;
      default:
        adsCategory="default";
        break;
    }
    return adsCategory;
  }

  public static formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate: string = new Intl.DateTimeFormat('en-US', options).format(date);
  
    // Extract the day from the formatted date
    const day: number  = Number(formattedDate.split(' ')[0]);
  
    // Add "th", "st", "nd", or "rd" suffix based on the day
    let daySuffix: string;
    if (day === 1 || day === 21 || day === 31) {
      daySuffix = 'st';
    } else if (day === 2 || day === 22) {
      daySuffix = 'nd';
    } else if (day === 3 || day === 23) {
      daySuffix = 'rd';
    } else {
      daySuffix = 'th';
    }
  
    // Replace the day in the formatted date with the formatted day
    const formattedDay: string = `${day}${daySuffix}`;
    const formattedDateWithDay:string = formattedDate.replace(String(day), formattedDay);
  
    return formattedDateWithDay;
  };
}