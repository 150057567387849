import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { AuthService } from "src/app/core/_auth/services/auth.service";
import { AuthAccountService } from "src/app/core/_services/auth.account.service";
import { AppState } from "src/app/core/_store/app.state";
import { AppConstants } from "src/app/core/_utils/appconstants";
import Swal from 'sweetalert2';

@Component({
    selector: 'app-mobile-navigation',
    templateUrl: './app-mobile-navigation.html',
    styleUrls: ['./app-mobile-navigation.scss'],
  })
  export class AppMobileNavigationComponent implements OnChanges, OnInit{
    @Input("categories") categories: any[];
    @Input("login_state") login_state: boolean;
    @Output('onSignInClick') onSignInClick = new EventEmitter<any>();
    @Output('onSignUpClick') onSignUpClick = new EventEmitter<any>();
    displayedCategories: any[] = [];
    showAll: boolean = false;
    noOfCategoriesToDisplay: number = 6;
    fullName: string;

    constructor(
        private router: Router,
        private cookieService: CookieService, 
        private authService: AuthService,
        private appState: AppState,
        private authAccountService: AuthAccountService
      ) {
      }
    
    ngOnInit(): void {
      let token = this.cookieService.get(AppConstants.COOKIE_TOKEN);
      let username = this.cookieService.get(AppConstants.USERNAME);

      if(token){
        this.appState.set(AppConstants.COOKIE_TOKEN, token);
        this.appState.set(AppConstants.USERNAME, username);

        this.authService.getUserDetails(username).subscribe(resp => {
          this.fullName = resp.firstname + ' ' + resp.lastname;    
        });
      }
    }

    ngOnChanges(){
      if(this.categories){
        this.updateDisplayedCategories();
      }
    }

    updateDisplayedCategories(): void {
      if (this.categories && this.categories.length > 0) {
        this.displayedCategories = this.showAll ? this.categories : this.categories.slice(0, this.noOfCategoriesToDisplay);
      }
    }
  
    showAllCategories(): void {
      this.showAll = true;
      this.updateDisplayedCategories();
    }

    logout() {
      Swal.fire({
        title: 'Logout as Customer?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#ff6d41',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout',
      }).then((result) => {
        if (result.isConfirmed) {
          this.authAccountService.clearUserSession();
          window.location.href = '/';
          // this.router.navigateByUrl("/");
        }
      });
    }

    navigagateToDeal(text: string){
        this.router.navigate(['/customer/product-search', { cat: text }]); 
    }

    navigateToLoginPopup(flag: boolean){
      this.onSignInClick.emit(flag);
    }

    navigateToSignupPopup(){
      this.onSignUpClick.emit();
    }
    
  }