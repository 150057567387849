import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    PLATFORM_ID,
  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from 'src/app/core/_store/app.state';
import { CommunicationService } from 'src/app/services/communication.service';
  
@Component({
    selector: 'partner-header',
    templateUrl: './partner-header.component.html',
    styleUrls: ['./partner-header.component.scss'],
})
export class BusinessHeaderComponent implements OnInit {
    loginPage: boolean;
    public screenWidth: any;
    @Output("navigateToLoginSignUp") navigateToLoginSignUpEvent = new EventEmitter<boolean>();

    constructor
    (
        private appState: AppState,
        private router: Router,
        private communicationService: CommunicationService,
        private route: ActivatedRoute
    ){
    }

    ngOnInit(): void {
        this.screenWidth = window.innerWidth;
        this.communicationService.manageLoginSignUpButtonClickedSource$.subscribe((data: boolean) => {
            this.loginPage = data;
        });
        this.loginPage = !this.router.url.includes("signup");
    }

    
    navigatePartnerSignIn(from: boolean): any {
        this.navigateToLoginSignUpEvent.emit(from);
    }

}   
