<div class="login-container d-flex justify-content-center container">
  <div class="login-form-wrap">
    <div class="login-form-header text-center">
      <h4 class="form-top-title">
        Maximize Your Business Reach with
        <span class="highlight">LocolDeal</span> Business
      </h4>
    </div>

    <ng-container *ngIf="!showResetPwd">
      <h2 class="text-center title">Business Log In</h2>
      <div class="login-form-inner">
        <form [formGroup]="loginForm" autocomplete="off">
          <div class="form-group">
            <label for="username" class="form-label">Email or Username</label>
            <input
              type="text"
              id="username"
              name="username"
              class="form-control"
              formControlName="username"
              placeholder="Enter Email or Username"
              [ngClass]="{ 'is-invalid': hasError('username', 'required') }"
            />

            <mat-error *ngIf="hasError('username', 'required')">
              Username/Email is required
            </mat-error>
          </div>
          <div class="form-group">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              class="form-control"
              formControlName="password"
              placeholder="Enter Password"
              [ngClass]="{ 'is-invalid': hasError('password', 'required') }"
            />

            <mat-error *ngIf="hasError('password', 'required')">
              Password is required
            </mat-error>
          </div>

          <!-- <div class="forgot-password">
            <a (click)="onForgotPwdClick()">Forgot Password?</a>
          </div> -->

          <div class="btn-wrap text-center">
            <button type="submit" class="btn btn-primary" (click)="submit()">
              Login
            </button>
          </div>
        </form>

        <div class="text-center mt-4">
          <span (click)="onSignupButtonClick()" class="signup-text">
            Don't have an account?
            <span class="sign-up-link">Create one</span>
          </span>
        </div>
      </div>
    </ng-container>

    <div *ngIf="showResetPwd">
      <app-reset-password (onCancel)="onResetPwdCancel()"></app-reset-password>
    </div>
  </div>
</div>
