import {
  AfterViewInit,
  Component,
  OnInit,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from '../../../core/_store/app.state';
import { ApiConstants } from '../../../core/_utils/apiconstants';
import { AppConstants } from '../../../core/_utils/appconstants';
import { RegisterUser } from '../../../core/_models/register.user';
import { ConfirmPasswordValidator } from '../confirm-password.validator';
import { RegistrationService } from 'src/app/core/_auth/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from 'jwt-decode';

declare var FB: any;
declare namespace fb {
  interface StatusResponse {}
}
declare var google: any;

@Component({
  selector: 'app-register-customer',
  templateUrl: './register-customer.component.html',
  styleUrls: ['./register-customer.component.scss'],
})
export class RegisterCustomerComponent implements OnInit, AfterViewInit {
  registrationForm: FormGroup;
  callbackOnRegister: Function;
  isCaptchaSubmitted: boolean;
  captchaSiteKey: string;
  isButtonDisabled = true;

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private registrationService: RegistrationService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isCaptchaSubmitted = false;
  }

  ngOnInit(): void {
    this.initRegistrationForm();
    this.registerCaptchaEvents();
    window.addEventListener('userAuthGoogleSignupCustomer', (event: any) => {
      let googleUserCred = jwt_decode(event.googletoken);
      this.signupUserWithGoogleToken(googleUserCred, event.googletoken);
    }); 
  }

  agreeTermsAndCondition(event: any) {
    if (event.target.checked) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  registerCaptchaEvents() {
    window.addEventListener('onCaptchaSubmitted', (event: any) => {
      this.isCaptchaSubmitted = event.result && 1 == 1;
    });
    window.addEventListener('onCaptchaNotSubmitted', (event: any) => {
      this.isCaptchaSubmitted = false;
    });
  }

  ngAfterViewInit() {
  }

  submit(userAutoVerified: boolean, loginTriggerCallback: Function) {
    this.isButtonDisabled = true;
    const controls = this.registrationForm.controls;
    // check form
    if (this.registrationForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched()
      );

      if(userAutoVerified){
        this.registrationForm.reset();
        this.toastr.warning(
          "Something is missing in the data"
        );
      }
      return;
    }

    if (!this.isCaptchaSubmitted && !userAutoVerified) {
      this.toastr.warning(
        "Please click on Capthca checkbox ('I'm not a robot.') before submit." +
          " If you don't see it just before 'Create Account' button, try refreshing the page."
      );
      return;
    }

    let registerUser: RegisterUser = new RegisterUser();
    registerUser.firstname = controls.firstname.value;
    registerUser.lastname = controls.lastname.value;
    registerUser.email = controls.email.value;
    registerUser.username = controls.username.value;
    registerUser.password = controls.password.value;
    registerUser.isVerified = userAutoVerified || false;
    registerUser.verified = userAutoVerified || false;

    this.registrationService.registerCustomer(registerUser).subscribe(
      (resp) => {
        this.toastr.success('Registration successful as Customer.', 'SUCCESS');
        this.registrationForm.reset();
        this.isButtonDisabled = false;
        if (userAutoVerified && loginTriggerCallback) {
          loginTriggerCallback();
        } else if (this.callbackOnRegister) {
          this.callbackOnRegister();
        }
        this.appState.set(AppConstants.VERIFY_EMAIL, registerUser.email);
        this.appState.set(AppConstants.VERIFY_USERNAME, registerUser.username);
      },
      (err) => {
        // TODO: handle error
        this.isButtonDisabled = false;
        if(userAutoVerified) this.registrationForm.reset();
        
        this.toastr.error(err.error.status, 'ERROR');
      }
    );
  }

  initRegistrationForm() {
    this.registrationForm = this.fb.group(
      {
        firstname: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        lastname: [
          null,
          Validators.compose([Validators.required, Validators.maxLength(100)]),
        ],
        email: [
          null,
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            Validators.maxLength(320), // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
          ]),
        ],
        username: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(50),
          ]),
        ],
        password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
        confirmPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      }
    );
  }

  hasError(controlName: string, validationType: string): boolean {
    const control = this.registrationForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  setCallbackOnRegister(callbackOnRegister: Function) {
    this.callbackOnRegister = callbackOnRegister;
  }

  signUpWithGoogle() {    // this just opens a prompt to Sign Up with Google
    var promptGLoginCustomEvent = document.createEvent("HTMLEvents");    
    promptGLoginCustomEvent.initEvent("promptGoogleSignup", true, true);    
    promptGLoginCustomEvent['google_client_id'] = this.appState.get(ApiConstants.GOOGLE_CLIENT_ID);
    window.dispatchEvent(promptGLoginCustomEvent);
  }

  signupUserWithGoogleToken(userData: any, googletoken: any) {
    this.toastr.info('Please wait.. while we register your profile.');
    
    let randomNum = Math.floor(Math.random() * 1000);
    let username = userData.email.split('@')[0] + '_' + randomNum; // so that unique username

    let formData = {
      firstname: userData.given_name,
      lastname: userData.family_name ?? " ",
      email: userData.email,
      username: username,
      password: userData.sub,
      confirmPassword: userData.sub,
    };

    this.registrationForm.setValue(formData);
    
    this.submit(true, () => {
      this.isCaptchaSubmitted = true; // bypass the captcha
      var customEvent = document.createEvent('HTMLEvents');
      customEvent.initEvent('userAuthGoogleSignin', true, true);
      customEvent['googletoken'] = googletoken;
      window.dispatchEvent(customEvent);
    });
  }

  signupWithFacebook() {
    if (typeof FB !== 'undefined') {
      new Promise<any>((resolve) => FB.getLoginStatus(resolve)).then(
        (fbResponse) => {
          this.fbLoginStatusChangeCallback(fbResponse);
        }
      );
    }
  }

  loginRetries = 0;
  fbLoginStatusChangeCallback(response: any) {
    if (response.status == 'connected') {
      this.signupUserWithFBToken(response?.authResponse?.accessToken);
    } else if (this.loginRetries <= 2) {
      this.loginRetries++;
      if (typeof FB !== 'undefined') {
        new Promise<any>((resolve) => FB.login(resolve)).then((fbResponse) => {
          this.fbLoginStatusChangeCallback(fbResponse);
        });
      }
    }
  }

  signupUserWithFBToken(fbToken: string) {
    new Promise<fb.StatusResponse>((resolve) =>
      FB.api(
        '/me',
        { fields: ['email', 'first_name', 'last_name', 'birthday', 'gender'] },
        resolve
      )
    ).then((fbResponse: any) => {
      if (
        !fbResponse.email ||
        !fbResponse.first_name ||
        !fbResponse.last_name
      ) {
        this.toastr.error(
          'Some of your Facebook profile details are not public (email, first name, last name), so can not sign up'
        );
        return;
      }

      let randomNum = Math.floor(Math.random() * 1000);
      let username = fbResponse.email.split('@')[0] + '_' + randomNum; // so that unique username

      // this fbResponse.id contains user FB account numerical ID
      let formData = {
        firstname: fbResponse.first_name,
        lastname: fbResponse.last_name,
        email: fbResponse.email,
        username: username,
        password: fbResponse.id,
        confirmPassword: fbResponse.id,
      };

      this.registrationForm.setValue(formData);

      this.submit(true, () => {
        this.isCaptchaSubmitted = true; // bypass the captcha
        // after signup to our system is done
        // dispatch event:userAuthFacebookSignin with 'fbtoken'
        var customEvent = document.createEvent('HTMLEvents');
        customEvent.initEvent('userAuthFacebookSignin', true, true);
        customEvent['fbToken'] = fbToken;
        window.dispatchEvent(customEvent);
      });
    });
  }
}
