<!-- <div class="d-flex align-items-center justify-content-center"> -->
  <div class="embla">
    <div class="embla__viewport">
      <div class="embla__container">
        <div class="embla__slide">
          <div class="embla__slide__inner">
            <div class="home_banner-inner">
              <div class="container custom-container">
                <div class="home_banner-contentwrap">
                  <div class="home_banner-contentwrap-inner">
                    <h3>
                      Discover Unbeatable Savings at
                      <span style="color: #ee410d">LocolDeal</span>
                    </h3>
                    <p>
                      Discover unbeatable discounts on a variety of products and services
                      tailored to enhance your local shopping experience. Don't miss out
                      on the savings – explore Local Deals today and elevate your shopping
                      journey!"
                    </p>
                    <div style="display: flex; align-items: center; justify-content: center">
          
                      <button type="button" class="custom-btn" title="View All Deals" (click)="browseCoupons()">
                        View All Deals
                      </button>
                    </div>
          
                    <!-- <div class="home_banner-imgwrap">
                      <img src="/assets/images/banner/mobile-Locoldeal.svg" alt="Locoldeal" />
                    </div> -->
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
        <div class="embla__slide">
          <div class="embla__slide__inner">
            <div class="home_banner-inner banner1">
              <div class="container custom-container">
                <div class="home_banner-contentwrap">
                  <div class="home_banner-contentwrap-inner">
                    <h3 style="color: black;">
                      Unlock Exclusive Savings At
                      <span style="color: #ee410d">LocolDeal</span>
                    </h3>
                    <p style="color: #616161;">
                      Locoldeal is the ultimate marketplace where consumers can find a variety of coupons and deals from their favorite local sellers.
                    </p>
                    <div style="display: flex; align-items: center; justify-content: center">
          
                      <button type="button" class="custom-btn" title="View All Deals" (click)="browseCoupons()">
                        View All Deals
                      </button>
                    </div>
          
                    <!-- <div class="home_banner-imgwrap">
                      <img src="/assets/images/banner/mobile-Locoldeal.svg" alt="Locoldeal" />
                    </div> -->
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
        <div class="embla__slide">
          <div class="embla__slide__inner">
            <div class="home_banner-inner banner2">
              <div class="container custom-container">
                <div class="home_banner-contentwrap">
                  <div class="home_banner-contentwrap-inner">
                    <h3 style="color: black;">
                      Unlock Your Savings Potential
                      <span style="color: #ee410d">LocolDeal</span>
                    </h3>
                    <p style="color: #616161;">
                      Welcome to <span style="color: #ee410d">LocolDeal</span> Your Trusted Destination for Unbeatable Local Discounts Discover a World Where Consumers Always Find the Best Deals and Discounts Tailored to Their Needs. Start Saving Today
                    </p>
                    <div style="display: flex; align-items: center; justify-content: center">
          
                      <button type="button" class="custom-btn" title="View All Deals" (click)="browseCoupons()">
                        View All Deals
                      </button>
                    </div>
          
                    <!-- <div class="home_banner-imgwrap">
                      <img src="/assets/images/banner/mobile-Locoldeal.svg" alt="Locoldeal" />
                    </div> -->
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
        <div class="embla__slide">
          <div class="embla__slide__inner">
            <div class="home_banner-inner banner3">
              <div class="container custom-container">
                <div class="home_banner-contentwrap">
                  <div class="home_banner-contentwrap-inner">
                    <h3 style="color: black;">
                      Discover Unbeatable Savings at
                      <span style="color: #ee410d">LocolDeal</span>
                    </h3>
                    <p style="color: #616161;">
                      Discover Endless Savings at <span style="color: #ee410d">LocolDeal</span> Your go-to destination where consumers always find the best local deals and discounts. Explore a diverse range of unbeatable offers tailored to enhance your shopping experience. Don't miss out on the savings  visit <span style="color: #ee410d">LocolDeal</span> today</p>
                    <div style="display: flex; align-items: center; justify-content: center">
          
                      <button type="button" class="custom-btn" title="View All Deals" (click)="browseCoupons()">
                        View All Deals
                      </button>
                    </div>
          
                    <!-- <div class="home_banner-imgwrap">
                      <img src="/assets/images/banner/mobile-Locoldeal.svg" alt="Locoldeal" />
                    </div> -->
                  </div>
                </div>
              </div>    
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Dots -->
    <div class="embla__dots"></div>
  
    <!-- Buttons -->
    <button class="embla__button embla__button--prev" type="button">
      <!-- <svg class="embla__button__svg" viewBox="137.718 -1.001 366.563 643.999">
        <path
          d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"
        ></path>
      </svg> -->
    </button>
    <button class="embla__button embla__button--next" type="button">
      <!-- <svg class="embla__button__svg" viewBox="0 0 238.003 238.003">
        <path
          d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"
        ></path>
      </svg> -->
    </button>
  </div>