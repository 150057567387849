
export class RegisterUser {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    username: string;
    password: string;
    partnerType: string;
    businessType: string;
    role: Role[]

    isVerified: boolean;
    verified: boolean;

    userId: number;

    website: string;
    accountSetup: boolean;
    description: string;
    businessName: string;
    businessAddress: string;
    addressLine1: string;
    addressLine2: string;
    businessCategory: string;
    photopath: string;
    sellerPhotos: string[];
}   

export class Role {
    roleName: string;
}