<div class="content-wrapper">
  <div class="intro-section">
    <div class="text-content">
      <h1>Promote your Business with LocolDeal</h1>
      <p>
        Join LocolDeal to reach more customers, increase your sales, and manage
        campaigns with ease. Launch offers in minutes and monitor performance
        with real-time insights.
      </p>
      <h2>Become a Partner Today</h2>
    </div>
  </div>

  <div class="main">

    <!-- <img src="/assets/images/Login/LadyWithLaptop.png" alt="Megaphone Image" /> -->

    <div class="registraton-form">
      <app-business-signup-form></app-business-signup-form>
    </div>
  </div>
</div>