<form [formGroup]="registrationForm" autocomplete="off">
  <div class="form-group">
    <input type="text" class="form-control" formControlName="firstname" placeholder="First Name"
      [ngClass]="{ 'is-invalid': hasError('firstname', 'required') }" />
    <mat-error *ngIf="hasError('firstname', 'required')">
      First Name is required
    </mat-error>
    <mat-error *ngIf="hasError('firstname', 'minlength')">
      Min 3 characters required
    </mat-error>
    <mat-error *ngIf="hasError('firstname', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
  </div>
  <div class="form-group">
    <input type="text" class="form-control" formControlName="lastname" placeholder="Last Name"
      [ngClass]="{ 'is-invalid': hasError('lastname', 'required') }" />
    <mat-error *ngIf="hasError('lastname', 'required')">
      Last Name is required
    </mat-error>
    <mat-error *ngIf="hasError('lastname', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
  </div>

  <div class="form-group">
    <input type="text" class="form-control" placeholder="Email" formControlName="email"
      [ngClass]="{ 'is-invalid': hasError('email', 'required') }" />
    <mat-error *ngIf="hasError('email', 'required')">
      Email is required
    </mat-error>
    <mat-error *ngIf="hasError('email', 'maxlength')">
      Max 100 characters are allowed
    </mat-error>
    <mat-error *ngIf="hasError('email', 'email')">
      Invalid Email address
    </mat-error>
  </div>

  <div class="form-group">
    <input type="text" class="form-control" placeholder="Username" formControlName="username"
      [ngClass]="{ 'is-invalid': hasError('username', 'required') }" />
    <mat-error *ngIf="hasError('username', 'required')">
      Username is required
    </mat-error>
    <mat-error *ngIf="hasError('username', 'minlength')">
      Username should be minimum 3 character
    </mat-error>
    <mat-error *ngIf="hasError('username', 'maxlength')">
      Username can be maximum 50 characters
    </mat-error>
  </div>

  <div class="form-group">
    <input type="password" class="form-control" placeholder="Password" formControlName="password"
      [ngClass]="{ 'is-invalid': hasError('password', 'required') }" />
    <mat-error *ngIf="hasError('password', 'required')">
      Password is required
    </mat-error>
    <mat-error *ngIf="hasError('password', 'minlength')">
      Password should be minimum 6 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('password', 'maxlength')">
      Password can be maximum 100 character (alpha-numeric)
    </mat-error>
  </div>

  <div class="form-group">
    <input type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword"
      [ngClass]="{ 'is-invalid': hasError('confirmPassword', 'required') }" />
    <mat-error *ngIf="hasError('confirmPassword', 'required')">
      Confirm Password is required
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'minlength')">
      Confirm Password should be minimum 6 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'maxlength')">
      Confirm Password can be maximum 100 character (alpha-numeric)
    </mat-error>
    <mat-error *ngIf="hasError('confirmPassword', 'ConfirmPassword')">
      Password does not match
    </mat-error>
  </div>

  <!-- this captcha site key is used from locoldealseo@gmail.com account, same is there in register-partner.component -->
  <!-- this reference callback functions captchaSubmitted() are defined in index.html -->
  <div class="g-recaptcha" data-callback="captchaSubmitted" data-expired-callback="captchaExpired"
    data-error-callback="captchaErrored" data-sitekey="6Ld0in8kAAAAAJicZKZtI3cvXjD3KupFvIYwtJQX"></div>
  <div class="form-group">
    <div style="
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin-top: 10px;
      ">
      <span class="Checkbox">
        <input type="checkbox" name="" (change)="agreeTermsAndCondition($event)" value="" />
      </span>

      <div>
        <label style="color: #000">
          I've read and agree with
          <a href="/customer/terms-conditions" target="_blank" style="cursor: pointer; color: #007bff">
            Terms of Service
          </a>
          and our
          <a href="/customer/privacy-policy" target="_blank" style="cursor: pointer; color: #007bff">
            Privacy Policy
          </a>
        </label>
      </div>
    </div>
  </div>

  <div class="btnWrap">
    <button type="submit" class="submit-btn" (click)="submit(false, undefined)" [disabled]="isButtonDisabled"
      [ngClass]="{ 'submit-btn-disabled': isButtonDisabled }">
      Sign Up
    </button>
  </div>

  <ul class="form-list social_media-list" style="margin-bottom: 1rem">
    <li>
      <ul class="innr-social-list">
        <!-- <li style="margin: 1rem 0rem 1rem 0rem">
          <h5>
            <span class="innr-text">Or Signup with</span>
          </h5>
        </li> -->

        <li>
          <div class="social_icon_list">
            <!-- <div class="icon_style">
              <img style="width: 40px; height: auto" class="img-fluid" src="../../../../assets/images/Login/twitter.png"
                alt="twitter_icon" />
            </div> -->
            <div class="icon_style" id="google-signup-btn">
              <!-- <img style="width: 40px; height: auto" class="img-fluid" src="../../../../assets/images/Login/google.png"
                alt="google_icon" /> -->
            </div>
            <div class="icon_style" (click)="signupWithFacebook()">
              <img style="width: 40px; height: auto" class="img-fluid"
                src="../../../../assets/images/Login/facebook.png" alt="facebook_icon" />
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</form>