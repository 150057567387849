<div class="login_formWrap">
  <div class="login_formWrap-inner" *ngIf="loginForm">
    <form [formGroup]="loginForm" (submit)="submit()" autocomplete="off">
      <div class="form-group">
        <label for="username" style="color: #000; font-size: 14px"
          >Username</label
        >
        <input
          type="text"
          id="username"
          name="username"
          class="form-control"
          formControlName="username"
          placeholder="Enter username"
          [ngClass]="{ 'is-invalid': hasError('username', 'required') }"
        />

        <mat-error *ngIf="hasError('username', 'required')">
          Username is required
        </mat-error>
      </div>
      <div class="form-group mb-2">
        <label for="password" style="color: #000; font-size: 14px"
          >Password</label
        >
        <input
          type="password"
          id="password"
          name="password"
          class="form-control"
          formControlName="password"
          placeholder="Enter Password"
          [ngClass]="{ 'is-invalid': hasError('password', 'required') }"
        />

        <mat-error *ngIf="hasError('password', 'required')">
          Password is required
        </mat-error>
      </div>

      <span class="forgot-pass">
        <i class="fa fa-lock" aria-hidden="true"></i>
        <a (click)="navigateToResetPwd()">Forgot Password</a></span
      >
      <div class="btnWrap">
        <button type="submit" class="submit-btn">Login</button>
      </div>
    </form>

    <ul class="form-list social_media-list" style="margin-bottom: 1rem">
      <li>
        <ul class="innr-social-list">
          <li style="margin: 1rem 0rem 1rem 0rem">
            <h5>
              <span class="innr-text">Or Login with</span>
            </h5>
          </li>
          <div class="social_icon_list">
            <!-- <div class="icon_style">
              <img style="width: 40px; height: auto" class="img-fluid" src="../../../../assets/images/Login/twitter.png"
                alt="twitter_icon" />
            </div> -->
            <div class="icon_style" id="google-signin-btn">
              <!-- <img style="width: 40px; height: auto" class="img-fluid" src="../../../../assets/images/Login/google.png"
                alt="google_icon" /> -->
            </div>
            <div class="icon_style" (click)="signInWithFacebook()">
              <img style="width: 40px; height: auto" class="img-fluid"
                src="../../../../assets/images/Login/facebook.png" alt="facebook_icon" />
            </div>
          </div>
        </ul>
      </li>
    </ul>

    <span
      style="color: #000000; font-size: 14px; cursor: pointer"
      (click)="navigateToLogin(true)"
      >Don't Have an Account ? <span style="font-weight: bold">SIGN UP</span>
    </span>
  </div>
</div>
