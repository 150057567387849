import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import $ from 'jquery';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/_auth/services/auth.service';
import { AuthAccountService } from 'src/app/core/_services/auth.account.service';
import { DealsService } from 'src/app/core/_services/deals.service';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { LoginComponent } from '../../auth-common/login/login.component';
import { RegisterCustomerComponent } from '../../auth-common/register-customer/register-customer.component';
import { RegisterPartnerComponent } from '../../auth-common/register-partner/register-partner.component';
import { isPlatformBrowser } from '@angular/common';
declare var google: any;

// import Swal from 'sweetalert2';
// import 'sweetalert2/dist/sweetalert2.css';
import { trigger } from '@angular/animations';
import { ApiConstants } from 'src/app/core/_utils/apiconstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Output('loginSignupClick') loginSignupClickEvent =
    new EventEmitter<string>();
  @Output('onUserLogin') onUserLoginEvent = new EventEmitter<string>(); // emits user role
  // @Input("isCustomer") isCustomer: boolean;

  @ViewChild('closeModal') closeModal: ElementRef;
  @ViewChild('modalLoginForm') modalLoginForm: ElementRef;
  @ViewChild('modalSignUpForm') modalSignupForm: ElementRef;

  private loginFormPopup: any;
  private signUpFormPopup: any;
  private resetPwdPopup: any;

  @ViewChild('navbarDropdownLayover') navbarDropdownLayover: ElementRef;

  login_state: boolean = false;

  @ViewChild(LoginComponent, { static: false })
  private loginComponent: LoginComponent;

  @ViewChild(RegisterPartnerComponent, { static: false })
  private registerPartnerComponent: RegisterPartnerComponent;

  @ViewChild(RegisterCustomerComponent, { static: false })
  private registerCustomerComponent: RegisterCustomerComponent;

  @ViewChild('AsUserSignUpButton') asUserSignUpButton: ElementRef;
  @ViewChild('forgotPasswordModal') forgotPasswordModal: ElementRef;

  showRegOption: boolean;
  hideRegisterCustomer: boolean;
  hideRegisterPartner: boolean;
  showVerifyEmail: boolean;
  verified: boolean;
  activeTab: ActiveTab;
  // registeringAs: string = ''; // for Tab label in signup dialog
  showResetPwd: boolean;

  chatToggle: boolean;
  userMenuToggle: boolean = false;
  notifWindowToggle: boolean = false;

  couponsOrDeals: boolean;

  public screenWidth: any;
  isfixedHeader: boolean = false;
  selectedContent: number = 1;

  currentPage: string = 'email';
  private isBrowser: boolean;

  constructor(
    private router: Router,
    private authAccountService: AuthAccountService,
    private authService: AuthService,
    private appState: AppState,
    private dealsService: DealsService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.activeTab = new ActiveTab();
    this.isBrowser = isPlatformBrowser(platformId);
  }

  userFullName: string;
  categoryMenuId: number;
  categoryHidden: string; // classname
  // searchText: string;
  // searchLocation: string;

  dealCategories: any[];
  beautyCategory: Array<string>;
  fashionCategory: Array<string>;
  brandCategory: Array<string>;
  electronicsCategory: Array<string>;
  foodsDrinksCategory: Array<string>;
  healthCategory: Array<string>;
  serviceCategory: Array<string>;

  ngOnInit(): void {
    this.login_state = this.authAccountService.isLoggdInAsCustomer();
    this.selectedContent = -1;
    // this.showRegOption = true;
    this.hideRegisterCustomer = true;
    this.hideRegisterPartner = true;
    this.showVerifyEmail = false;
    this.verified = false;
    this.showResetPwd = false;
    this.screenWidth = window.innerWidth;
    this.categoryHidden = 'd-none';
    // this.searchText = '';
    // this.searchLocation = '';

    this.loadDealCategories();
    this.isActive(0);

    // document.getElementById('closeCategoryMenu').addEventListener('click', function(event) {
    //   event.stopPropagation();
    //   document.getElementById('navbarDropdownLayover').classList.remove('show');
    // });

    this.beautyCategory = [
      'Tanning',
      'Brows & Lashes',
      'Salons',
      'Face & Skin Care',
      'Hair removal',
      'Beauty & Spas',
      'Spas',
      'Message',
      'Procedures',
      'Hair Removal',
      'Hair & Stylings',
      'Makeups',
      'Nail Salons',
      'Blowouts & Stylings',
    ];
    this.fashionCategory = [
      'Assos',
      'New Look',
      'Huge Boos',
      '11 Degrees',
      '54 Floral',
      'A 2 B Fashion',
      'Air & Grace',
      'AMI PARIS',
      'Andies Bountique',
      'Aspire Wear',
    ];
    this.brandCategory = [
      'Boden',
      'Bottletop',
      'Kimai',
      'Raeburn',
      'Sandqvist',
      'We are Knitters',
      'Nike',
      'Puma',
    ];
    this.electronicsCategory = [
      'Croma',
      'Samsung',
      'Amazon',
      'Dell',
      'Apple',
      'Asus',
      'Deep cool',
      'G-skill',
      'Lian-Li',
      'One plus',
      'LG',
    ];
    this.foodsDrinksCategory = [
      'Restaurants',
      'Breweries, Wineries & Distlleries',
      'Cafes & Treats',
      'Groceries & Markets',
      'Bars',
    ];
    this.healthCategory = [
      'Gyms',
      'Weight Loss',
      'Fitness Classes',
      'Medical',
      'Natural Medicine',
      'Sports',
      'Dental',
      'Vision',
    ];
    this.serviceCategory = [
      'Automotive Repair',
      'Home Services',
      'Meal Prep & Delivery',
      'Pet Care',
      'Professional Services',
      'Office Supplies',
      'Auto Sales',
      'Contractors',
      'Insurance',
      'Online Learning',
      'Retail',
    ];
  }

  @HostListener('document:click', ['$event'])
  clickOutsideHideUserMenu(event: any) {
    // console.log(event.target);
    if (event.target.id == 'navbarDropdown') {
      return;
    }
    if (
      event.target.id == 'dropdownMenuLink' ||
      event.target.parentNode.id == 'dropdownMenuLink' ||
      event.target.id == 'dealsToggleSwitch' ||
      event.target.id == 'dealsToggle'
    ) {
      setTimeout(() => {
        document
          .getElementById('navbarDropdownLayover')
          ?.classList?.add('show');
        document
          .getElementById('navbarDropdownLayover')
          ?.classList?.remove('collapse');
      }, 1);
    } else {
      setTimeout(() => {
        document
          .getElementById('navbarDropdownLayover')
          ?.classList?.remove('show');
        document
          .getElementById('navbarDropdownLayover')
          ?.classList?.add('collapse');
      }, 1);
    }
    return;
    // this.userMenuToggle = false;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isfixedHeader = window.pageYOffset >= 70;
  }

  loadDealCategories() {
    this.dealsService.getDealCategoryNames().subscribe((resp) => {
      this.dealCategories = resp as any[];
    });
  }

  // Method to set the current page from the header
  setCurrentPage(page: string) {
    this.currentPage = page;
  }

  onCurrentPageChange(newPage: string) {
    this.currentPage = newPage; // Update the currentPage when emitted
  }

  // doSearch() {
  //   console.log(this.searchText, this.searchLocation);
  //   this.router.navigate([
  //     '/customer/product-search',
  //     { q: this.searchText, location: this.searchLocation },
  //   ]);
  // }

  showCategoryMenu(tabId: number) {
    // if (event.target instanceof HTMLElement) {
    //   (event.target.parentNode as HTMLElement).classList.add('activeTab');
    // }
    // var tabcontent = document.getElementsByClassName('header_categories-listWrap-body-inner');
    // for (let i = 0; i < tabcontent.length; i++) {
    //   (tabcontent[i] as HTMLElement).style.display = "none";
    // }
    // (tabcontent[tabId] as HTMLElement).style.display = "grid";
    // this.categoryMenuId = tabId;
    this.selectedContent = tabId;
  }

  isActiveCategory(tabId: number): boolean {
    return tabId === this.selectedContent;
  }

  setActiveCategory(tabId: number) {
    this.selectedContent = tabId;
  }

  isActive(tabId: number): boolean {
    return tabId === this.categoryMenuId;
  }

  hideCategoryMenu() {
    this.categoryHidden = 'd-none';
  }

  // showContent(content: number) {
  //   this.selectedContent = content;
  // }
  // logout() {
  //   Swal.fire({
  //     title: 'Logout as Customer?',
  //     icon: 'info',
  //     showCancelButton: true,
  //     confirmButtonColor: '#ff6d41',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Logout',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       this.authAccountService.clearUserSession();
  //       window.location.href = '/';
  //       // this.router.navigateByUrl("/");
  //     }
  //   });
  // }

  toggleChatWindow() {
    this.chatToggle = !this.chatToggle;
  }

  onChatClose($event) {
    this.chatToggle = false;
  }

  resetPasswordToRedirect(){
    this.openresetPwdPopup(false);
    this.openLoginPopup(true);
  }

  // 1-> open, 2-> close
  openLoginPopup(flag: boolean) {
    if(flag){
      this.initializeSignInGoogle();  
      this.loginFormPopup.show();
    }
    else{
      this.loginFormPopup.hide();
    }
  }

  // 1-> open, 2-> close
  openSignupPopup(flag: boolean) {
    if(flag){
      this.initializeSignUpGoogle();    
      this.signUpFormPopup.show();
    }
    else{
      this.signUpFormPopup.hide();
    }
  }

  openresetPwdPopup(flag: boolean) {
    if(flag){
      this.closeModal.nativeElement.click();
      this.resetPwdPopup.show();
    }
    else{
      this.resetPwdPopup.hide();
    }
  }

  initializeSignInGoogle() {
    if (google) {
      google.accounts.id.initialize({
        client_id: this.appState.get(ApiConstants.GOOGLE_CLIENT_ID),
        callback: this.signInCallback,
        context: "signin",
      });
      google.accounts.id.renderButton(document.getElementById("google-signin-btn"), {
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangle',
        width: 100,
        text: "signin_with",
        type: "icon"
      });
    }
  }

  signInCallback(response) {
    console.log(response);
    var customEvent = document.createEvent("HTMLEvents");
    customEvent.initEvent("userAuthGoogleSignin", true, true);
    customEvent["googletoken"] = response.credential;
    window.dispatchEvent(customEvent);
  }

  initializeSignUpGoogle() {
    if (google) {
      google.accounts.id.initialize({
        client_id: this.appState.get(ApiConstants.GOOGLE_CLIENT_ID),
        callback: this.signupCallback,
        context: "signup",
      });

      google.accounts.id.renderButton(document.getElementById("google-signup-btn"), {
        theme: 'filled_blue',
        size: 'large',
        shape: 'rectangle',
        width: 100,
        text: "signup_with",
        type: "icon"
      })
    }
  }

  signupCallback(response) {
    console.log(response);
    console.log("got google ID response, signup");
    var customEvent = document.createEvent("HTMLEvents");
    customEvent.initEvent("userAuthGoogleSignupCustomer", true, true);
    customEvent["googletoken"] = response.credential;
    window.dispatchEvent(customEvent);
  }
  // ------------

  ngAfterViewInit() {
    // if (this.registerPartnerComponent) {
    //   this.registerPartnerComponent.setCallbackOnRegister(() => {
    //     this.showVerifyEmail = true;
    //     this.hideRegisterPartner = true;
    //   });
    // }
    
    if (this.isBrowser) {
      setTimeout(() => {
        import('bootstrap').then(({ Modal }) => {
          if (this.modalLoginForm) {
            this.loginFormPopup = new Modal(this.modalLoginForm.nativeElement);
          }
          this.signUpFormPopup = new Modal(this.modalSignupForm.nativeElement);
          this.resetPwdPopup = new Modal(this.forgotPasswordModal.nativeElement);
        }).catch(err => console.error('Error loading Modal:', err));
      }, 0);
    }

    if (this.registerCustomerComponent) {
      this.registerCustomerComponent.setCallbackOnRegister(() => {
        this.showVerifyEmail = true;
        this.hideRegisterCustomer = true;
      });
    }

    if (this.loginComponent) {
      this.loginComponent.setOpenVerifyEmail(() => {
        this.showVerifyEmail = true;
        // this.activateTab('signup');
        // this.showRegOption = false;
      });
    }

    if (this.navbarDropdownLayover) {
      this.renderer.listen(
        this.navbarDropdownLayover.nativeElement,
        'click',
        (event) => {
          event.stopPropagation();
        }
      );
    }
  }

  callbackOnVerified() {
    this.verified = true;
    this.showVerifyEmail = false;
    // this.activeTab.login = 'active';
    // this.activeTab.signup = '';
    this.showResetPwd = false;
  }

  callbackOnVerifyCancel() {
    // this.showRegOption = true;
    this.showVerifyEmail = false;
    // this.activeTab.login = 'active';
    // this.activeTab.signup = '';
  }

  registerAsCustomer() {
    this.openSignupPopup(true);
    this.hideRegisterCustomer = false;
    // this.showRegOption = false;
    // this.registeringAs = 'Consumer';
  }

  registerAsPartner() {
    this.hideRegisterPartner = false;
    // this.showRegOption = false;
    // this.registeringAs = 'Partner';
  }

  // activateTab(tab: string) {
  //   if (tab == 'login') {
  //     this.activeTab.login = 'active show';
  //     this.activeTab.signup = '';
  //   } else {
  //     // in show
  //     this.hideRegisterCustomer = true;
  //     // this.showRegOption = true;
  //     this.registeringAs = '';
  //     this.hideRegisterPartner = true;

  //     // this.showRegOption = false;
  //     this.registeringAs = 'Consumer';

  //     this.activeTab.login = '';
  //     this.activeTab.signup = 'active show';
  //   }
  // }

  onLoginSuccess(event) {
    // console.log('login success', event);
    this.login_state = true;
    this.closeModal.nativeElement.click();
    this.navigateUser(event);
    // navigate to /app only if not on some page
    // if (this.router.url.indexOf('/') == -1)
    // this.router.navigateByUrl("/app");
  }

  navigateUser(username: string) {
    this.authService.getUserDetails(username).subscribe(
      (resp) => {
        let currentUser = resp;
        let fullName = resp.firstname + ' ' + currentUser.lastname;
        this.appState.set(AppConstants.USER_FULLNAME, fullName);
        if (currentUser.role) {
          this.appState.set(AppConstants.USER_ROLE, resp.role[0]);
        }
        this.appState.set(AppConstants.USERNAME, username);
        this.appState.set(AppConstants.USER_ID, resp.userId);

        // TODO: check user role for navigation
        if (currentUser.role[0].roleName == 'SITE_PARTNER') {
          this.onUserLoginEvent.emit('SITE_PARTNER');
          this.router.navigateByUrl('/partner');
        } else if (currentUser.role[0].roleName == 'SITE_USER') {
          this.onUserLoginEvent.emit('SITE_USER');
          window.location.href = '/customer';
          // this.router.navigateByUrl('/');
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  toggleUserMenu() {
    this.userMenuToggle = !this.userMenuToggle;
  }

  ngOnDestroy() {
    this.closeModal.nativeElement.click();
    this.modalLoginForm.nativeElement.click();
  }

  onForgotPwdClick() {
    // this.showResetPwd = true;
    // this.openForgotPasswordModal();
    this.openresetPwdPopup(true);
  }

  onResetPwdCancel() {
    this.showResetPwd = false;
  }

  navigateToSearch(tabId: number, text: string) {
    if (tabId === 1) {
      this.router.navigate(['/customer/product-search', { cat: text }]);
    } else {
      this.router.navigate(['/customer/coupons-browse', { category: text }]);
    }
  }

  toggleNotifWindow() {
    this.notifWindowToggle = !this.notifWindowToggle;
  }

  // true - signup, false-signin
  signUpButtonClickEvent(from: boolean) {
    if (from) {
      this.closeModal.nativeElement.click();
      setTimeout(() => {
        (this.asUserSignUpButton.nativeElement as HTMLElement).click();
      }, 100);
    }
  }
}

class ActiveTab {
  login: string = 'active';
  signup: string = '';
}
