<form [formGroup]="verifyEmailForm" autocomplete="off">
  <div class="form-group">
    <label>Username</label>
    <input
      type="text"
      readonly
      class="form-control"
      value="{{ user.username }}"
    />
  </div>
  <div class="form-group">
    <label>Email</label>
    <input
      formControlName="email"
      class="form-control"
      placeholder="Enter Email"
      (change)="onEmail()"
    />
  </div>

  <div class="form-group">
    <label for="code" style="color: #000; font-size: 14px">Code</label>

    <input
      type="text"
      id="code"
      name="code"
      class="form-control"
      formControlName="code"
      placeholder="Enter code"
      [ngClass]="{ 'is-invalid': hasError('code', 'required') }"
    />

    <mat-error *ngIf="hasError('code', 'required')">
      TWO Factor Code is required, check your email for it.
    </mat-error>
  </div>

  <div class="btnWrap">
    <button type="submit" class="submit-btn" (click)="submit()">Submit</button>
    <button type="button" class="cancel-btn mt-2" (click)="cancel()">
      Cancel
    </button>
  </div>
</form>
