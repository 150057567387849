import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DealsService } from "src/app/core/_services/deals.service";
import { AppState } from "src/app/core/_store/app.state";
import { AppConstants } from "src/app/core/_utils/appconstants";

@Component({
  selector: "app-deal-categories",
  templateUrl: "./deal-categories.component.html",
  styleUrls: ["./deal-categories.component.scss"],
})
export class DealCategoriesComponent implements OnInit {
  categories: any[] = [];
  active: number = 0;
  items: any[] = [];
  noImageAvailable: string;

  images: any = {
    "Auto Sales": "assets/images/categories/category_auto_sales.svg",
    Automotive: "assets/images/categories/category_automotive.svg",
    Cannabis: "assets/images/categories/category_cannabis.svg",
    Clothing: "assets/images/categories/category_cloth.svg",
    "Electronics and Computers": "assets/images/categories/category_computer.svg",
    Contractors: "assets/images/categories/category_contractors.svg",
    "Entertainment and Events": "assets/images/categories/category_entertainment_events.svg",
    Entertainment: "assets/images/categories/category_entertainment.svg",
    "Food & Dining": "assets/images/categories/category_food_dining.svg",
    "Health and Beauty": "assets/images/categories/category_health_beauty.svg",
    "Home and Garden": "assets/images/categories/category_home.svg",
    "Baby and Kids": "assets/images/categories/category_kids.svg",
    "Office supplies": "assets/images/categories/category_office_supplies.svg",
    "Pet Care": "assets/images/categories/category_pet_care.svg",
    "Pet Supplies": "assets/images/categories/category_pet_supplies.svg",
    "Professional Services": "assets/images/categories/category_professional_services.svg",
    Restaurants: "assets/images/categories/category_restaurants.svg",
    "Shopping/Retail": "assets/images/categories/category_shopping_retail.svg",
    "Sports and Fitness": "assets/images/categories/category_sports_fitness.svg",
    Insurance: "assets/images/categories/category_insurance.svg",
    "Travel and Accommodation": "assets/images/categories/category_travel_accommodation.svg",
  };

  constructor(private dealsService: DealsService, private router: Router, private toastr: ToastrService, private appState: AppState) {
    this.noImageAvailable = "../../../../assets/images/no-image.png";
  }

  ngOnInit(): void {
    this.dealsService.getAllTopCategories().subscribe((data) => {
      this.categories = Object.keys(data).map((key) => ({
        title: key,
        item: data[key].slice(0, 8),
      }));

      if (this.categories.length > 0) {
        this.items = this.categories[0].item;
      }
    });
  }

  viewAll() {
    this.router.navigate(["/customer/coupons-browse"]);
  }

  setActive(index: number) {
    this.active = index;
    this.items = this.categories[index].item;
  }

  getThisCoupon() {
    if (!this.loginState()) {
      window.scroll(0, 0);
      this.toastr.error("To activate this Coupon you must sign in as consumer.", "Sign In");
    } else {
      this.router.navigateByUrl("/customer/coupons-browse");
    }
  }

  loginState() {
    if (this.appState.get(AppConstants.USERNAME)) {
      return true;
    } else {
      return false;
    }
  }
}
