import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ChatMessageService } from 'src/app/core/_services/chat.message.service';
import { AppState } from 'src/app/core/_store/app.state';
import { AppConstants } from 'src/app/core/_utils/appconstants';
import { CommonsUtils } from 'src/app/core/_utils/commons.utils';
import { Message } from '../../../core/_models/message';
import { MessageThread } from '../../../core/_models/message.thread';
import { ScrollToBottomDirective } from '../scroll-to-bottom.directive';
import { SocketService } from 'src/app/services/socket.service';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../../../services/communication.service';


@Component({
  selector: 'app-customer-chat',
  templateUrl: './customer-chat.component.html',
  styleUrls: ['./customer-chat.component.css'],
})
export class CustomerChatComponent implements OnInit {

  @ViewChild(ScrollToBottomDirective)
  scroll: ScrollToBottomDirective;

  @ViewChild("msgInputBox") msgInputBox:ElementRef;

  @Output("close") closeEvent = new EventEmitter<boolean>();

  customerUsername: string;

  sendMessageForm: FormGroup;

  messageThreads: MessageThread[];
  allMessageThreads: MessageThread[];
  currentMessageThread: MessageThread;

  currentThreadMessages: Message[];
  displayChatContainer: boolean = true;
  unreadMessages: { };
  messages: any;
  filterByThreadPerson: string;
  msgThreadLoaded: boolean = false;
  iscurrentThreadMessagesLoaded:boolean = false;
  userRole: string;

  constructor(
      private chatMessageService: ChatMessageService, 
      private appState: AppState,
      private formBuilder: FormBuilder,
      private toastr: ToastrService,
      private router: Router,
      private ws:SocketService,
      private communicationService: CommunicationService,
      private changeDetectorRef: ChangeDetectorRef
    ) {
    this.currentMessageThread = undefined;
    this.messageThreads = [];
    this.currentThreadMessages = [];
  }

  ngOnInit(): void {
    this.customerUsername = this.appState.get(AppConstants.USERNAME);
    this.userRole = this.appState.get(AppConstants.USER_ROLE).roleName; 
    
    this.createSendMessageForm();
    this.loadMessagThreads();
    this.ws.messagedChanges$.subscribe((message: any) => {
      if (this.currentMessageThread?.messageId == message.messageId) {
        const index = this.currentThreadMessages.findIndex(o => o.message === message.message && o.messageId===message.messageId && !o.isSent);
        (message as Message).isSent = true;
        if (index === -1) {
            this.currentThreadMessages.push(message);
        } else {
            this.currentThreadMessages[index] = message;
        }
        this.ws.deleteReadNotificationMessage(this.currentMessageThread.messageId);
        this.ws.updateNotificationCount();  
      }
      if (message.senderName === this.customerUsername) {
        console.log(message.senderName);
      }

    });

    this.communicationService.getMessageRoomRedirectionEvent().subscribe(resp => {
      this.selectedMessageThread(resp);
    });
    this.unreadMessages = this.ws.messageNotificationCollector;
  }

  createSendMessageForm(){
    this.sendMessageForm = this.formBuilder.group({
      sendMessageText: ['', Validators.required],
    });
  }

  loadMessagThreads() {
    this.msgThreadLoaded = false;

    this.userRole == 'SITE_PARTNER' ?
      this.chatMessageService.getMessageThreadsProvider(this.customerUsername).subscribe(resp => {
        this.msgThreadLoaded = true;
        this.messageThreads = resp as MessageThread[];
        this.allMessageThreads = this.messageThreads;
      }) : this.chatMessageService.getMessageThreadsConsumer(this.customerUsername).subscribe(resp => {
        this.msgThreadLoaded = true;
        this.messageThreads = resp as MessageThread[];
        this.allMessageThreads = this.messageThreads;
      }); 
  }

  loadMessages(messageId: number) {
    this.currentThreadMessages = [];
    this.ws.deleteReadNotificationMessage(messageId);
    this.ws.updateNotificationCount();
    this.unreadMessages = this.ws.messageNotificationCollector;
    this.iscurrentThreadMessagesLoaded = false;
    this.chatMessageService.getMessagesForThread(messageId).subscribe(resp => {
      this.currentThreadMessages = resp.msgThreads as Message[];
      this.currentThreadMessages.forEach(obj => {
        obj.isSent = true
      });
      this.iscurrentThreadMessagesLoaded = true;
    });
  }

  selectedMessageThread(msgThread_: MessageThread) { 
    this.currentMessageThread = msgThread_;
    msgThread_.messageConsumerRead = true;
    this.userRole == 'SITE_PARTNER' ?
      this.chatMessageService.markMessageReadProvider(this.currentMessageThread.messageId+'').subscribe(resp => {
        console.log('message opened');
      }) :
      this.chatMessageService.markMessageReadConsumer(this.currentMessageThread.messageId+'').subscribe(resp => {
        console.log('message opened');
      });
    this.loadMessages(this.currentMessageThread.messageId);
  }

  deSelectedMessageThread() {
   this.currentMessageThread = undefined;
    this.currentThreadMessages = [];
  }

  chatToggle(){
    this.closeEvent.emit(true);
  }

  sendMessage() {    
    console.log(this.sendMessageForm.value);
    if (this.sendMessageForm.invalid) {
      console.log('form invalid');
      return;
    }
    let messageObj = new Message();
    messageObj.messageId = this.currentMessageThread.messageId;
    messageObj.providerName = this.currentMessageThread.providerName;
    messageObj.consumerName = this.customerUsername;
    messageObj.senderName = this.customerUsername;
    messageObj.message = this.sendMessageForm.controls.sendMessageText.value;
    this.currentThreadMessages.push(messageObj);
    this.sendMessageForm.reset();
    this.ws.sendMessage(messageObj)
      .then(() => {
        this.msgInputBox.nativeElement.focus();
        })
      .catch((error) => {
        this.toastr.error('Failed to send message.', 'ERROR');
        console.error('Error sending message:', error);
      });
  }

  sendAttachmentInMessage() {
    let element = document.querySelector("#attachment") as HTMLElement;
    element.click();
  }

  attachmentSelected($event: any) {
    
    let messageObj = new Message();
    messageObj.messageId = this.currentMessageThread.messageId;
    messageObj.providerName = this.currentMessageThread.providerName;
    messageObj.consumerName = this.customerUsername;
    messageObj.senderName = this.customerUsername;
    messageObj.message = 'Sent an Attachment';

    const formData = new FormData();
    formData.append('jsondata', JSON.stringify(messageObj));
    formData.append('file', $event.target.files[0])

    this.chatMessageService.sendMessageAttachment(formData).subscribe(resp => {
      this.toastr.success("Message sent.", "SUCCESS");
      this.loadMessages(this.currentMessageThread.messageId);
      this.sendMessageForm.reset();
      this.ws.sendMessage(resp);
    }, err => {
      this.toastr.error("Failed to send Message.", "ERROR");
    });
  }

  openDeal(messageThread: MessageThread) {
    let url = '/customer/deal-view/' + messageThread.dealId;
    setTimeout(() => {
      this.closeEvent.emit(true);
    }, 100);
    this.router.navigateByUrl(url);
  }

  messageSentTime(message: Message) {
    if (!message.created_DT) return '';
    let date: Date = new Date(message.created_DT);

    // Format the date as DD/MM/YYYY
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    let year = date.getFullYear();

    // Format the time as HH:MM AM/PM
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let strTime = hours + ':' + minutes + ' ' + ampm;

    return `${day}/${month}/${year} at ${strTime}`;
  }

  filterByName() {
    if(this.filterByThreadPerson){
      this.messageThreads = this.messageThreads.filter(msg => msg.dealName.toLowerCase().includes(this.filterByThreadPerson.toLowerCase()));
    }
    else{
      this.messageThreads = this.allMessageThreads;
    }
  }

  // ---  UTILS  --- //

  nameInitial(name: string) {
    return CommonsUtils.nameInitial(name);
  }
}
