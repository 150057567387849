<section *ngIf="categories.length > 0" class="deal_category-wrapper">
  <div class="container">
    <div class="deal_category-contentWrap">
      <div class="container deal_category-head">
        <h2 class="category-heading fw-semibold">Categories You May Like</h2>
        <a class="category-view-all fw-medium" (click)="viewAll()">View All</a>
      </div>

      <div class="deal_category-content">
        <div class="container">
          <div class="row g-4">
            <div class="col-12 col-md-12 col-lg-3">
              <div class="deal_category-tablist">
                <div
                  *ngFor="let category of categories; let i = index"
                  class="deal_category_iconWrap"
                  [ngClass]="{ active: active === i }"
                  role="tab"
                  (click)="setActive(i)"
                >
                  <img [src]="images[category.title]" alt="{{ category.title }}" />
                  <span class="deal_category_label fw-medium">{{ category.title }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-9">
              <div class="deal_category-tabcontent">
                <div class="container">
                  <div class="row g-4">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let item of items">
                      <div class="card-content-box">
                        <img [src]="item.brandLogo" (error)="item.brandLogo = noImageAvailable" alt="{{ item.brandName }}" />
                        <div class="card-content">
                          <h6 class="card-item-heading fw-medium" title="{{ item.description }}">{{ item.shortTitle }}</h6>
                          <p class="card-item-expire fw-medium">Expire On {{ item.validUpTo | date : "MMM d, y" }}</p>
                          <div class="text-center">
                            <button (click)="getThisCoupon()" class="btn show-coupon-btn">Show coupon code</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
